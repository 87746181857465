import { useState, useEffect } from 'react';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';

// components
import Cards from '../cards/Cards';
import Recipe from '../recipe/Recipe';

// swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// style
import './Swiper.scss';

export default function SwiperCard({ ...props }) {
  const [localRecipes, setLocalRecipes] = useState<any>([]);

  useEffect(() => {
    if (props.recipes.length !== 0) {
      setLocalRecipes(props.recipes);
    }
  }, [props.recipes]);

  const updateLocalRecipesRate = (id: number, rate: number) => {
    const newLocalRecipes = localRecipes.map((recipe: any) => {
      if (recipe.id === id) {
        recipe.rate = rate;
      }
      return recipe;
    });
    setLocalRecipes(newLocalRecipes);
  };

  const updateLocalRecipesComments = (id: number, comments: any) => {
    const newLocalRecipes = localRecipes.map((recipe: any) => {
      if (recipe.id === id) {
        recipe.comments = comments;
      }
      return recipe;
    });
    setLocalRecipes(newLocalRecipes);
  };

  return (
    <Swiper
      style={{ width: '100%', height: '55vh' }}
      slidesPerView={3}
      centeredSlides={false}
      navigation={true}
      modules={[Autoplay, Navigation]}
      breakpoints={{
        2300: { slidesPerView: 5, spaceBetween: 10 },
        1920: { slidesPerView: 4, spaceBetween: 10 },
        1690: { slidesPerView: 3, spaceBetween: 10 },
        1200: { slidesPerView: 2, spaceBetween: 50 },
        1024: { slidesPerView: 2, spaceBetween: 20 },
        768: { slidesPerView: 1, spaceBetween: 20 },
        640: { slidesPerView: 1, spaceBetween: 20 },
        500: { slidesPerView: 1, spaceBetween: 20 },
        320: { slidesPerView: 1, spaceBetween: 20 },
      }}
      className='mySwiper'>
      {props.modalRecipe !== undefined && (
        <Recipe
          {...props}
          localRecipes={localRecipes}
          updateLocalRecipesRate={updateLocalRecipesRate}
          updateLocalRecipesComments={updateLocalRecipesComments}
        />
      )}
      {props.recipes.message === undefined &&
        localRecipes !== undefined &&
        localRecipes.length !== 0 &&
        localRecipes.error === undefined &&
        // order by resultTag if exist
        localRecipes
          .sort((a: any, b: any) => {
            return a.resultTag - b.resultTag;
          })
          .map((recipe: any) => {
            return (
              <SwiperSlide key={recipe.id}>
                <Cards recipe={recipe} {...props} />
              </SwiperSlide>
            );
          })}
    </Swiper>
  );
}
