export default function RatingText({ ...props }) {
  return (
    <p
      style={{
        color: '#aab1bd',
      }}>
      {props.modalRecipe.rate === 'null' ||
      props.modalRecipe.rate === 0 ||
      props.modalRecipe.rate === undefined
        ? ' Pas de note'
        : ' ' + props.modalRecipe.rate + '/5'}
    </p>
  );
}
