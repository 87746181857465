import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

// components
import Rating from '../../ratingCards/Rating';

// nutriscore
import A from '../../../assets/nutriscore/nutri-score-A.svg.png';
import B from '../../../assets/nutriscore/nutri-score-B.svg.png';
import C from '../../../assets/nutriscore/nutri-score-C.svg.png';
import D from '../../../assets/nutriscore/nutri-score-D.svg.png';
import E from '../../../assets/nutriscore/nutri-score-E.svg.png';

const style = {
  height: '3rem',
  width: '6rem',
};

export default function CardsHeader({ ...props }) {
  return (
    <CardContent>
      <Rating {...props} />
      <Typography
        variant='body2'
        color='text.secondary'
        style={{
          color: 'white',
          fontSize: '0.8rem',
          fontWeight: '500',
          textAlign: 'left',
          marginTop: '0',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          height: '1rem',
        }}>
        {props.localCommentsList !== undefined &&
        props.localCommentsList.length !== 0 ? (
          <span>
            {props.localCommentsList.length}{' '}
            {props.localCommentsList.length > 1 ? 'avis' : 'avis'}
          </span>
        ) : (
          <span>Aucun avis</span>
        )}
        {props.recipe.nutriments.nutriscore === 'A' ? (
          <img src={A} alt='nutriscore A' style={style} />
        ) : props.recipe.nutriments.nutriscore === 'B' ? (
          <img src={B} alt='nutriscore B' style={style} />
        ) : props.recipe.nutriments.nutriscore === 'C' ? (
          <img src={C} alt='nutriscore C' style={style} />
        ) : props.recipe.nutriments.nutriscore === 'D' ? (
          <img src={D} alt='nutriscore D' style={style} />
        ) : props.recipe.nutriments.nutriscore === 'E' ? (
          <img src={E} alt='nutriscore E' style={style} />
        ) : (
          ''
        )}
      </Typography>
    </CardContent>
  );
}
