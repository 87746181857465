import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// components
import RatingText from './ratingText';
import tools from '../../service/fetch';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export default function BasicRating({ ...props }) {
  const [idRecipe, setIdRecipe] = useState(0);
  const [rate, setRate] = useState(0);
  const [disableRate, setDisableRate] = useState(false);
  const [textRate, setTextRate] = useState(false);
  const [toggle, setToggle] = useState(false);

  const toggleRate = () => {
    setToggle(!toggle);
  };

  const adjustRate = (newvalue: number) => {
    let newRate: any = (rate + newvalue) / 2;
    newRate = newRate.toFixed(1);
    // remove the last 0 and the dot
    if (newRate[newRate.length - 1] === '0') {
      newRate = newRate.slice(0, newRate.length - 2);
    }
    return newRate;
  };

  const toggleChangeRate = async (event: any) => {
    const query = adjustRate(parseFloat(event.target.value));
    const response = await tools.patchRecipeRate(idRecipe, query);
    if (response.status === 200) {
      setRate(parseFloat(query));
      props.updateLocalRecipesRate(idRecipe, parseFloat(query));
      setDisableRate(true);
      setTextRate(true);
      toggleRate();
      setTimeout(() => {
        setTextRate(false);
      }, 1000);
      localStorage.setItem(`rating_${idRecipe.toString()}`, 'true');
    }
  };

  useEffect(() => {
    setIdRecipe(props.modalRecipeId);
    if (
      localStorage.getItem(`rating_${props.modalRecipe.id.toString()}`) ===
      'true'
    ) {
      setDisableRate(true);
    } else {
      setDisableRate(false);
    }
  }, [
    props.open,
    props.modalRecipeId,
    props.modalRecipe.rate,
    props.modalRecipe.id,
  ]);

  useEffect(() => {
    setRate(parseFloat(props.modalRecipe.rate));
  }, [props.modalRecipe.rate]);

  return (
    <Box
      sx={{
        '& > legend': { mt: 2 },
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexShrink: 1,
      }}>
      <StyledRating
        sx={{ fontSize: '2rem' }}
        readOnly={disableRate}
        size='large'
        name='simple-controlled'
        value={rate}
        precision={0.25}
        onChange={(event) => {
          toggleChangeRate(event);
        }}
        icon={<FavoriteIcon fontSize='inherit' />}
        emptyIcon={<FavoriteBorderIcon fontSize='inherit' />}
      />
      <RatingText {...props} rate={rate} />
      {disableRate && (
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{
            color: '#aab1bd',
            fontSize: '0.75rem',
            transition: 'all 2s ease-in-out',
            opacity: textRate ? 1 : 0,
            marginLeft: '0.5rem',
          }}>
          Merci pour votre vote !
        </Typography>
      )}
    </Box>
  );
}
