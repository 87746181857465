import cheese_1 from '../../../assets/icone/cheese/cheese 1.png';
import cheese_2 from '../../../assets/icone/cheese/cheese 2.png';
import cheese_3 from '../../../assets/icone/cheese/cheese 3.png';
import cheese_4 from '../../../assets/icone/cheese/cheese 4.png';
import cheese_5 from '../../../assets/icone/cheese/cheese 5.png';
import cheese_6 from '../../../assets/icone/cheese/cheese 6.png';
import cheese_7 from '../../../assets/icone/cheese/cheese 7.png';
import cheese_8 from '../../../assets/icone/cheese/cheese 8.png';
import cheese_9 from '../../../assets/icone/cheese/cheese 9.png';
import cheese_10 from '../../../assets/icone/cheese/cheese 10.png';
import cheese_11 from '../../../assets/icone/cheese/cheese 11.png';
import cheese_12 from '../../../assets/icone/cheese/cheese 12.png';
import cheese_13 from '../../../assets/icone/cheese/cheese 13.png';
import cheese_14 from '../../../assets/icone/cheese/cheese 14.png';
import cheese_15 from '../../../assets/icone/cheese/cheese 15.png';
import cheese_16 from '../../../assets/icone/cheese/cheese 16.png';
import cheese_17 from '../../../assets/icone/cheese/cheese 17.png';
import cheese_18 from '../../../assets/icone/cheese/cheese 18.png';
import cheese_19 from '../../../assets/icone/cheese/cheese 19.png';
import cheese_20 from '../../../assets/icone/cheese/cheese 20.png';
import cheese_21 from '../../../assets/icone/cheese/cheese 21.png';
import cheese_22 from '../../../assets/icone/cheese/cheese 22.png';
import cheese_23 from '../../../assets/icone/cheese/cheese 23.png';
import cheese_24 from '../../../assets/icone/cheese/cheese 24.png';
import cheese_25 from '../../../assets/icone/cheese/cheese 25.png';
import cheese_26 from '../../../assets/icone/cheese/cheese 26.png';
import cheese_27 from '../../../assets/icone/cheese/cheese 27.png';
import cheese_28 from '../../../assets/icone/cheese/cheese 28.png';
import cheese_29 from '../../../assets/icone/cheese/cheese 29.png';
import cheese_30 from '../../../assets/icone/cheese/cheese 30.png';
import cheese_31 from '../../../assets/icone/cheese/cheese 31.png';
import cheese_32 from '../../../assets/icone/cheese/cheese 32.png';
import cheese_33 from '../../../assets/icone/cheese/cheese 33.png';
import cheese_34 from '../../../assets/icone/cheese/cheese 34.png';

const cheeseList: Array<{ name: string; avatar_url: string; id: string }> = [
  {
    name: '',
    avatar_url: '',
    id: 'cheese_0',
  },
  {
    name: 'Chèvre',
    avatar_url: cheese_1,
    id: 'cheese_1',
  },
  {
    name: 'Emmental',
    avatar_url: cheese_2,
    id: 'cheese_2',
  },
  {
    name: 'Vache-qui-rit',
    avatar_url: cheese_3,
    id: 'cheese_3',
  },
  {
    name: 'Kiri',
    avatar_url: cheese_4,
    id: 'cheese_4',
  },
  {
    name: 'Fromage-frais',
    avatar_url: cheese_5,
    id: 'cheese_5',
  },
  {
    name: 'Comté',
    avatar_url: cheese_6,
    id: 'cheese_6',
  },
  {
    name: 'Camembert',
    avatar_url: cheese_7,
    id: 'cheese_7',
  },
  {
    name: 'Coulommier',
    avatar_url: cheese_8,
    id: 'cheese_8',
  },
  {
    name: 'Gruyère',
    avatar_url: cheese_9,
    id: 'cheese_9',
  },
  {
    name: 'Roquefort',
    avatar_url: cheese_10,
    id: 'cheese_10',
  },
  {
    name: 'Beaufort',
    avatar_url: cheese_11,
    id: 'cheese_11',
  },
  {
    name: 'Bleu',
    avatar_url: cheese_12,
    id: 'cheese_12',
  },
  {
    name: 'Brie',
    avatar_url: cheese_13,
    id: 'cheese_13',
  },
  {
    name: 'Cabécou',
    avatar_url: cheese_14,
    id: 'cheese_14',
  },
  {
    name: 'Cantal',
    avatar_url: cheese_15,
    id: 'cheese_15',
  },
  {
    name: 'Cheddar',
    avatar_url: cheese_16,
    id: 'cheese_16',
  },
  {
    name: 'Crottin-de-chèvre',
    avatar_url: cheese_17,
    id: 'cheese_17',
  },
  {
    name: 'Gouda',
    avatar_url: cheese_18,
    id: 'cheese_18',
  },
  {
    name: 'Mascarpone',
    avatar_url: cheese_19,
    id: 'cheese_19',
  },
  {
    name: 'Mimolette',
    avatar_url: cheese_20,
    id: 'cheese_20',
  },
  {
    name: "Mont-d'or",
    avatar_url: cheese_21,
    id: 'cheese_21',
  },
  {
    name: 'Morbier',
    avatar_url: cheese_22,
    id: 'cheese_22',
  },
  {
    name: 'Munster',
    avatar_url: cheese_23,
    id: 'cheese_23',
  },
  {
    name: 'Raclette',
    avatar_url: cheese_24,
    id: 'cheese_24',
  },
  {
    name: 'Reblochon',
    avatar_url: cheese_25,
    id: 'cheese_25',
  },
  {
    name: 'Rocamadour',
    avatar_url: cheese_26,
    id: 'cheese_26',
  },
  {
    name: 'Saint-félicien',
    avatar_url: cheese_27,
    id: 'cheese_27',
  },
  {
    name: 'Saint-Marcelin',
    avatar_url: cheese_28,
    id: 'cheese_28',
  },
  {
    name: 'Saint-nectaire',
    avatar_url: cheese_29,
    id: 'cheese_29',
  },
  {
    name: 'Tomme',
    avatar_url: cheese_30,
    id: 'cheese_30',
  },
  {
    name: 'Vacherin',
    avatar_url: cheese_31,
    id: 'cheese_31',
  },
  {
    name: 'Fromage-rapé',
    avatar_url: cheese_32,
    id: 'cheese_32',
  },
  {
    name: 'Mozzarella',
    avatar_url: cheese_33,
    id: 'cheese_33',
  },
  {
    name: 'Feta',
    avatar_url: cheese_34,
    id: 'cheese_34',
  },
];

export default cheeseList;
