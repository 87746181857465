export default function RatingText({ ...props }) {
  return (
    <p
      style={{
        color: '#aab1bd',
      }}>
      {props.localRate === 'null' ||
      props.localRate === 0 ||
      props.localRate === undefined
        ? ' Pas de note'
        : ' ' + props.localRate + '/5'}
    </p>
  );
}
