/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

// style
import './Footer.scss';

export default function Footer({ ...props }): ReactElement {
  const [color, setColor] = useState('white');
  const [color2, setColor2] = useState('white');
  const [color3, setColor3] = useState('white');
  const [color4, setColor4] = useState('white');
  const [color5, setColor5] = useState('white');

  const date = new Date();
  const alphabet = [
    '#',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  const twoCalls = (letter: string) => {
    props.getRecipeBySearch(letter);
    props.setHomeLoader(true);
  };

  return (
    <footer className='footer'>
      <div className='container'>
        <p className='alphabet_search'>Index des ingrédients</p>
        <div className='row'>
          {alphabet.map((letter) => (
            <p
              className='letter'
              key={letter}
              onClick={() => {
                twoCalls(letter);
              }}>
              {letter}
            </p>
          ))}
        </div>
      </div>
      <p className='copyrigth'>
        {' '}
        © {date.getFullYear()} - frigovide.com | Préparé et mitonné par{' '}
        <a
          style={{ color: color, transition: 'all 0.3s ease' }}
          href='https://webplayground.fr'
          target={'_blank'}
          rel='noreferrer'
          onMouseEnter={() => {
            setColor('#7bedff');
          }}
          onMouseLeave={() => {
            setColor('white');
          }}>
          Webplayground
        </a>{' '}
        | Logo par{' '}
        <a
          style={{ color: color2, transition: 'all 0.3s ease' }}
          href='https://bearweb.fr'
          target={'_blank'}
          rel='noreferrer'
          onMouseEnter={() => {
            setColor2('#7bedff');
          }}
          onMouseLeave={() => {
            setColor2('white');
          }}>
          Bearweb {''}
        </a>
        |
        <a
          style={{ color: color5, transition: 'all 0.3s ease' }}
          onClick={() => {
            props.contact.handleOpenContact(true);
          }}
          href='#'
          rel='noreferrer'
          onMouseEnter={() => {
            setColor5('#7bedff');
          }}
          onMouseLeave={() => {
            setColor5('white');
          }}>
          {' '}
          Contact {''}
        </a>
        |
        <a
          style={{ color: color3, transition: 'all 0.3s ease' }}
          onClick={() => {
            props.setOpenCookies(true);
          }}
          href='#'
          rel='noreferrer'
          onMouseEnter={() => {
            setColor3('#7bedff');
          }}
          onMouseLeave={() => {
            setColor3('white');
          }}>
          {' '}
          Cookies {''}
        </a>
        |{' '}
        <Link
          to='/cgu'
          style={{ color: color4, transition: 'all 0.3s ease' }}
          onMouseEnter={() => {
            setColor4('#7bedff');
          }}
          onMouseLeave={() => {
            setColor4('white');
          }}>
          CGU
        </Link>
      </p>
    </footer>
  );
}
