import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// components
import IngredientTypeList from './IngredientsType';

export default function Fridge({ ...props }) {
  const descriptionElementRef = useRef<HTMLElement>(null);
  const [frigoSearch, setFrigoSearch] = useState('');
  const [virtualFrigo, setVirtualFrigo] = useState<any>([]);
  const [activeButton, setActiveButton] = useState(false);

  useEffect(() => {
    if (virtualFrigo.length > 0) {
      const active = virtualFrigo.some((item: any) => item.checked === true);
      setActiveButton(active);
    } else {
      setActiveButton(false);
    }
  }, [virtualFrigo]);

  const clearFridge = () => {
    localStorage.removeItem('virtualFrigo');
    setVirtualFrigo([]);
    props.setHomeLoader(true);
    props.getLastRecipes();
  };

  const handleFrigoSearch = () => {
    if (frigoSearch.length === 0) {
      return props.fridge.handleCloseFridge();
    }
    // create params object
    const params = virtualFrigo.filter((item: any) =>
      item.id.includes('params'),
    );
    props.setHomeLoader(true);
    props.getByFridge(frigoSearch, params);
    props.fridge.handleCloseFridge();
  };

  const handleCheck = (event: any) => {
    const { name, checked, id } = event.target;
    const newVirtualFrigo = [...virtualFrigo];
    const index = newVirtualFrigo.findIndex((item: any) => item.name === name);

    if (index !== -1) {
      newVirtualFrigo[index].checked = checked;
    } else {
      newVirtualFrigo.push({ name, checked, id });
    }

    setVirtualFrigo(newVirtualFrigo);
    localStorage.setItem('virtualFrigo', JSON.stringify(newVirtualFrigo));
  };

  useEffect(() => {
    if (props.fridge.openModalFrige) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.fridge.openModalFrige]);

  useEffect(() => {
    setFrigoSearch(
      virtualFrigo
        .filter((item: any) => !item.id.includes('params'))
        .filter((item: any) => item.checked === true)
        .map((item: any) => item.name)
        .join(','),
    );
  }, [virtualFrigo]);

  useEffect(() => {
    setVirtualFrigo(
      localStorage.getItem('virtualFrigo')
        ? JSON.parse(localStorage.getItem('virtualFrigo') || '')
        : [],
    );
  }, []);

  return (
    <div>
      <Dialog
        fullWidth
        open={props.fridge.openModalFrige}
        onClose={() => props.fridge.handleCloseFridge()}
        scroll='paper'
        aria-labelledby='ingredient-type-dialog-list'
        aria-describedby='ingredients-list-selection'>
        <DialogTitle id='scroll-dialog-title' color={'#4bc2e4'}>
          Mon frigo vide virtuel
        </DialogTitle>
        <DialogContent sx={{ overflow: 'hidden', minHeight: '4rem' }}>
          Selectionner les ingrédients que vous avez dans votre frigo, et
          cliquez sur "Valider" pour trouver des recettes.
        </DialogContent>
        {/* <DialogContent> */}
        <IngredientTypeList
          setFrigoSearch={setFrigoSearch}
          virtualFrigo={virtualFrigo}
          handleCheck={handleCheck}
          {...props}
        />
        {/* </DialogContent> */}
        <DialogActions>
          <Button onClick={clearFridge}>Effacer</Button>
          <Button onClick={() => props.fridge.handleCloseFridge()}>
            Fermer
          </Button>
          <Button onClick={handleFrigoSearch} disabled={!activeButton}>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
