import vegetables_1 from '../../../assets/icone/vegetables/vegetables 1.png';
import vegetables_2 from '../../../assets/icone/vegetables/vegetables 2.png';
import vegetables_3 from '../../../assets/icone/vegetables/vegetables 3.png';
import vegetables_4 from '../../../assets/icone/vegetables/vegetables 4.png';
import vegetables_5 from '../../../assets/icone/vegetables/vegetables 5.png';
import vegetables_6 from '../../../assets/icone/vegetables/vegetables 6.png';
import vegetables_7 from '../../../assets/icone/vegetables/vegetables 7.png';
import vegetables_8 from '../../../assets/icone/vegetables/vegetables 8.png';
import vegetables_9 from '../../../assets/icone/vegetables/vegetables 9.png';
import vegetables_10 from '../../../assets/icone/vegetables/vegetables 10.png';
import vegetables_11 from '../../../assets/icone/vegetables/vegetables 11.png';
import vegetables_12 from '../../../assets/icone/vegetables/vegetables 12.png';
import vegetables_13 from '../../../assets/icone/vegetables/vegetables 13.png';
import vegetables_14 from '../../../assets/icone/vegetables/vegetables 14.png';
import vegetables_15 from '../../../assets/icone/vegetables/vegetables 15.png';
import vegetables_16 from '../../../assets/icone/vegetables/vegetables 16.png';
import vegetables_17 from '../../../assets/icone/vegetables/vegetables 17.png';
import vegetables_18 from '../../../assets/icone/vegetables/vegetables 18.png';
import vegetables_19 from '../../../assets/icone/vegetables/vegetables 19.png';
import vegetables_20 from '../../../assets/icone/vegetables/vegetables 20.png';
import vegetables_21 from '../../../assets/icone/vegetables/vegetables 21.png';
import vegetables_22 from '../../../assets/icone/vegetables/vegetables 22.png';
import vegetables_23 from '../../../assets/icone/vegetables/vegetables 23.png';
import vegetables_24 from '../../../assets/icone/vegetables/vegetables 24.png';
import vegetables_25 from '../../../assets/icone/vegetables/vegetables 25.png';
import vegetables_26 from '../../../assets/icone/vegetables/vegetables 26.png';
import vegetables_27 from '../../../assets/icone/vegetables/vegetables 27.png';
import vegetables_28 from '../../../assets/icone/vegetables/vegetables 28.png';
import vegetables_29 from '../../../assets/icone/vegetables/vegetables 29.png';
import vegetables_30 from '../../../assets/icone/vegetables/vegetables 30.png';
import vegetables_31 from '../../../assets/icone/vegetables/vegetables 31.png';
import vegetables_32 from '../../../assets/icone/vegetables/vegetables 32.png';
import vegetables_33 from '../../../assets/icone/vegetables/vegetables 33.png';
import vegetables_34 from '../../../assets/icone/vegetables/vegetables 34.png';
import vegetables_35 from '../../../assets/icone/vegetables/vegetables 35.png';
import vegetables_36 from '../../../assets/icone/vegetables/vegetables 36.png';
import vegetables_37 from '../../../assets/icone/vegetables/vegetables 37.png';
import vegetables_38 from '../../../assets/icone/vegetables/vegetables 38.png';
import vegetables_39 from '../../../assets/icone/vegetables/vegetables 39.png';
import vegetables_40 from '../../../assets/icone/vegetables/vegetables 40.png';
import vegetables_41 from '../../../assets/icone/vegetables/vegetables 41.png';
import vegetables_42 from '../../../assets/icone/vegetables/vegetables 42.png';
import vegetables_43 from '../../../assets/icone/vegetables/vegetables 43.png';

const vegetablesList: Array<{ name: string; avatar_url: string; id: string }> =
  [
    {
      name: '',
      avatar_url: '',
      id: 'vegetables_0',
    },
    {
      name: 'Aubergine',
      avatar_url: vegetables_1,
      id: 'vegetables_1',
    },
    {
      name: 'Avocat',
      avatar_url: vegetables_2,
      id: 'vegetables_2',
    },
    {
      name: 'Brocoli',
      avatar_url: vegetables_3,
      id: 'vegetables_3',
    },
    {
      name: 'Carotte',
      avatar_url: vegetables_4,
      id: 'vegetables_4',
    },
    {
      name: 'Céleri',
      avatar_url: vegetables_5,
      id: 'vegetables_5',
    },
    {
      name: 'Champignon',
      avatar_url: vegetables_6,
      id: 'vegetables_6',
    },
    {
      name: 'Chou',
      avatar_url: vegetables_7,
      id: 'vegetables_7',
    },
    {
      name: 'Chou-fleur',
      avatar_url: vegetables_8,
      id: 'vegetables_8',
    },
    {
      name: 'Concombre',
      avatar_url: vegetables_9,
      id: 'vegetables_9',
    },
    {
      name: 'Cornichon',
      avatar_url: vegetables_10,
      id: 'vegetables_10',
    },
    {
      name: 'Maïs',
      avatar_url: vegetables_11,
      id: 'vegetables_11',
    },

    {
      name: 'Salade',
      avatar_url: vegetables_12,
      id: 'vegetables_12',
    },

    {
      name: 'Olive',
      avatar_url: vegetables_13,
      id: 'vegetables_13',
    },
    {
      name: 'Oignon',
      avatar_url: vegetables_14,
      id: 'vegetables_14',
    },

    {
      name: 'Pomme-de-terre',
      avatar_url: vegetables_15,
      id: 'vegetables_15',
    },
    {
      name: 'Citrouille',
      avatar_url: vegetables_16,
      id: 'vegetables_16',
    },
    {
      name: 'Échalote',
      avatar_url: vegetables_17,
      id: 'vegetables_17',
    },
    {
      name: 'Épinard',
      avatar_url: vegetables_18,
      id: 'vegetables_18',
    },
    {
      name: 'Patate-douce',
      avatar_url: vegetables_19,
      id: 'vegetables_19',
    },
    {
      name: 'Tomate',
      avatar_url: vegetables_20,
      id: 'vegetables_20',
    },
    {
      name: 'Courgette',
      avatar_url: vegetables_21,
      id: 'vegetables_21',
    },
    {
      name: 'Haricots-verts',
      avatar_url: vegetables_22,
      id: 'vegetables_22',
    },
    {
      name: 'Artichaut',
      avatar_url: vegetables_23,
      id: 'vegetables_23',
    },
    {
      name: 'Asperge',
      avatar_url: vegetables_24,
      id: 'vegetables_24',
    },
    {
      name: 'Betterave',
      avatar_url: vegetables_25,
      id: 'vegetables_25',
    },
    {
      name: 'Courge',
      avatar_url: vegetables_26,
      id: 'vegetables_26',
    },
    {
      name: 'Topinambour',
      avatar_url: vegetables_27,
      id: 'vegetables_27',
    },
    {
      name: 'Endive',
      avatar_url: vegetables_28,
      id: 'vegetables_28',
    },
    {
      name: 'Fenouil',
      avatar_url: vegetables_29,
      id: 'vegetables_29',
    },
    {
      name: 'Fève',
      avatar_url: vegetables_30,
      id: 'vegetables_30',
    },
    {
      name: 'Flageolet',
      avatar_url: vegetables_31,
      id: 'vegetables_31',
    },
    {
      name: 'Lentille',
      avatar_url: vegetables_32,
      id: 'vegetables_32',
    },
    {
      name: 'Manioc',
      avatar_url: vegetables_33,
      id: 'vegetables_33',
    },
    {
      name: 'Navet',
      avatar_url: vegetables_34,
      id: 'vegetables_34',
    },
    {
      name: 'Petit-pois',
      avatar_url: vegetables_35,
      id: 'vegetables_35',
    },
    {
      name: 'Poireau',
      avatar_url: vegetables_36,
      id: 'vegetables_36',
    },
    {
      name: 'Poivron',
      avatar_url: vegetables_37,
      id: 'vegetables_37',
    },
    {
      name: 'Potimarron',
      avatar_url: vegetables_38,
      id: 'vegetables_38',
    },
    {
      name: 'Potiron',
      avatar_url: vegetables_39,
      id: 'vegetables_39',
    },
    {
      name: 'Radis',
      avatar_url: vegetables_40,
      id: 'vegetables_40',
    },
    {
      name: 'Salsifi',
      avatar_url: vegetables_41,
      id: 'vegetables_41',
    },
    {
      name: 'Haricot-blanc',
      avatar_url: vegetables_42,
      id: 'vegetables_42',
    },
    {
      name: 'Haricot-rouge',
      avatar_url: vegetables_43,
      id: 'vegetables_43',
    },
  ];

export default vegetablesList;
