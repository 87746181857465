import { useState, useEffect } from 'react';

// tools
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import tools from '../../../service/fetch';
import generator from '../../../service/tools';
import dayjs from 'dayjs';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox CGU' } };

export default function RecipeReviewCard({ ...props }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [colorButton, setColorButton] = useState<any>('primary');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [, setToken] = useState('');
  const [commentsList, setCommentsList] = useState<any>([]);
  const [toggleSendComment, setToggleSendComment] = useState(false);
  const recipe_id: number = props.modalRecipe.id;
  const [checked, setChecked] = useState(false);

  const handleCheck = (event: any) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const sendComment = await tools.addComment(recipe_id, {
      name,
      email,
      comment,
      recipe_id,
    });
    if (sendComment.status === 200) {
      setName('');
      setEmail('');
      setComment('');
      setColorButton('success');
      props.toggleUpdateComment();
      localStorage.setItem(`comment_${recipe_id.toString()}`, 'true');
    } else {
      setColorButton('error');
    }
  };

  const sendComment = async (e: any) => {
    try {
      e.preventDefault();
      if (!executeRecaptcha) {
        return;
      }
      const result = await executeRecaptcha('contact');
      setToken(result);
      handleSubmit(e);
    } catch (error) {
      setColorButton('error');
    }
  };

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    const handleReCaptchaVerify = async () => {
      const token2 = await executeRecaptcha('contact');
      setToken(token2);
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  useEffect(() => {
    setCommentsList(props.listOfComments);
    props.updateLocalRecipesComments(recipe_id, props.listOfComments);
    if (
      localStorage.getItem(`comment_${props.modalRecipe.id.toString()}`) ===
      'true'
    ) {
      setToggleSendComment(true);
      setColorButton('success');
    } else {
      setToggleSendComment(false);
      setColorButton('primary');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listOfComments, props.modalRecipe.id]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        '& > div': {
          borderRight: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider',
        },
        minHeight: '42vh',
      }}>
      <Grid
        {...{ xs: 12, sm: 12, md: 6, lg: 6 }}
        sx={{ overflowY: 'auto', maxHeight: '41vh' }}>
        {commentsList !== undefined ? (
          commentsList.map((comment: any) => (
            <Card
              sx={{ maxWidth: 345, margin: '0 auto 1rem auto' }}
              key={generator.generateKey()}>
              <CardHeader
                sx={{
                  '& span:nth-of-type(1)': {
                    fontSize: '1.2rem',
                    color: '#4bc2e4',
                  },
                  '& span:nth-of-type(2)': {
                    fontSize: '0.8rem',
                  },
                }}
                title={
                  'Par ' +
                  comment.name.charAt(0).toUpperCase() +
                  comment.name.slice(1)
                }
                subheader={
                  'Le ' + dayjs(comment.created_at).format('DD/MM/YYYY')
                }
              />
              <CardContent>
                <Typography variant='body2' color='text.secondary'>
                  {comment.content}
                </Typography>
              </CardContent>
            </Card>
          ))
        ) : (
          <Card
            sx={{ maxWidth: 345, margin: '0 auto 1rem auto' }}
            key={generator.generateKey()}>
            <CardHeader
              sx={{
                '& span:nth-of-type(1)': {
                  fontSize: '1.2rem',
                  color: '#4bc2e4',
                },
                '& span:nth-of-type(2)': {
                  fontSize: '0.8rem',
                },
              }}
              title='frigovide.fr'
              subheader={'Le ' + dayjs(Date.now()).format('DD/MM/YYYY')}
            />
            <CardContent>
              <Typography variant='body2' color='text.secondary'>
                Aucun commentaire pour le moment
              </Typography>
            </CardContent>
          </Card>
        )}
      </Grid>
      <Grid
        {...{ xs: 12, sm: 12, md: 6, lg: 6 }}
        sx={{ minHeight: '41vh', width: '100%' }}>
        <Grid
          container
          spacing={2}
          sx={{
            minHeight: '42vh',
            alignContent: 'space-evenly',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
          }}>
          <form
            method='post'
            onSubmit={(e) => sendComment(e)}
            style={{ width: '100%' }}>
            <Grid {...{ lg: 12 }} sx={{ width: '100%' }}>
              <TextField
                disabled={toggleSendComment || !checked}
                sx={{ width: '100%' }}
                id='outlined-basic'
                label='Nom'
                variant='outlined'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid {...{ lg: 12 }}>
              <TextField
                disabled={toggleSendComment}
                sx={{ width: '100%' }}
                id='outlined-basic'
                label='Email'
                variant='outlined'
                type={'email'}
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                FormHelperTextProps={{
                  sx: {
                    color: 'red',
                  },
                }}
                helperText={
                  email.length > 0 && !email.includes('@')
                    ? 'Email non valide'
                    : ''
                }
              />
            </Grid>
            <Grid {...{ lg: 12 }}>
              <TextField
                disabled={toggleSendComment}
                id='outlined-multiline-flexible'
                label='Commentaire'
                multiline
                maxRows={4}
                minRows={2}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid
              {...{ lg: 12 }}
              sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox {...label} onChange={(e) => handleCheck(e)} />
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{ display: 'inline-block', fontSize: '0.75rem' }}>
                J'accepte les{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href='/cgu' target={'_blanck'}>
                  conditions générales d'utilisation
                </a>
              </Typography>
            </Grid>
            <Grid {...{ lg: 12 }}>
              <Button
                disabled={toggleSendComment || !checked}
                variant='outlined'
                type='submit'
                sx={{ width: '100%' }}
                color={colorButton}>
                {colorButton === 'success' && (
                  <span role='img' aria-label='emoji'>
                    👍
                  </span>
                )}
                {colorButton === 'error' && (
                  <span role='img' aria-label='emoji'>
                    👎
                  </span>
                )}
                {colorButton === 'primary' && 'Envoyer'}
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
}
