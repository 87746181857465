import meat_1 from '../../../assets/icone/meat/meat 1.png';
import meat_2 from '../../../assets/icone/meat/meat 2.png';
import meat_3 from '../../../assets/icone/meat/meat 3.png';
import meat_4 from '../../../assets/icone/meat/meat 4.png';
import meat_5 from '../../../assets/icone/meat/meat 5.png';
import meat_6 from '../../../assets/icone/meat/meat 6.png';
import meat_7 from '../../../assets/icone/meat/meat 7.png';
import meat_8 from '../../../assets/icone/meat/meat 8.png';
import meat_9 from '../../../assets/icone/meat/meat 9.png';
import meat_10 from '../../../assets/icone/meat/meat 10.png';
import meat_11 from '../../../assets/icone/meat/meat 11.png';
import meat_12 from '../../../assets/icone/meat/meat 12.png';
import meat_13 from '../../../assets/icone/meat/meat 13.png';

const MeatsList: Array<{ name: string; avatar_url: string; id: string }> = [
  {
    name: '',
    avatar_url: '',
    id: 'meat_0',
  },
  {
    name: 'Bacon',
    avatar_url: meat_1,
    id: 'meat_1',
  },
  {
    name: 'Boeuf',
    avatar_url: meat_2,
    id: 'meat_2',
  },
  {
    name: 'Poulet',
    avatar_url: meat_3,
    id: 'meat_3',
  },
  {
    name: 'jambon',
    avatar_url: meat_4,
    id: 'meat_4',
  },
  {
    name: 'Agneau',
    avatar_url: meat_5,
    id: 'meat_5',
  },
  {
    name: 'Porc',
    avatar_url: meat_6,
    id: 'meat_6',
  },
  {
    name: 'Salami',
    avatar_url: meat_7,
    id: 'meat_7',
  },
  {
    name: 'Saucisson',
    avatar_url: meat_8,
    id: 'meat_8',
  },
  {
    name: 'Dinde',
    avatar_url: meat_9,
    id: 'meat_9',
  },
  {
    name: 'Lard',
    avatar_url: meat_10,
    id: 'meat_10',
  },
  {
    name: 'Veau',
    avatar_url: meat_11,
    id: 'meat_11',
  },
  {
    name: 'mouton',
    avatar_url: meat_12,
    id: 'meat_12',
  },
  {
    name: 'Saucisse',
    avatar_url: meat_13,
    id: 'meat_13',
  },
];
export default MeatsList;
