import { useEffect, useState } from 'react';

// tools
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';

import tools from '../../../service/adjustRecipe';

const buttonStyle = {
  height: '100%',
  width: '100%',
  backgroundColor: '#d8dee8',
};

export default function RecipePeople({ ...props }) {
  const [people, setPeople] = useState(0);
  const maxPeople = 20;
  const colorMinus =
    people === tools.defaultPeople / 2 ? 'secondary' : 'primary';
  const colorPlus = people === maxPeople ? 'secondary' : 'primary';

  const changePeople = (data: string) => {
    switch (data) {
      case 'add':
        if (people < maxPeople) {
          setPeople(people + 1);
          props.toggle();
        }
        break;
      case 'remove':
        if (people > tools.defaultPeople / 2) {
          setPeople(people - 1);
          props.toggle();
        }
        break;
    }
  };

  useEffect(() => {
    setPeople(parseInt(props.modalRecipe.people));
  }, [props.modalRecipe, props.open]);

  useEffect(() => {
    if (props.open) {
      tools.ingredients = [];
      tools.people = 0;
      tools.defaultPeople = 0;
      tools.people = people;
      tools.defaultPeople = parseInt(props.modalRecipe.people);
      props.modalRecipe.ingredients.map((ingredient: string) => {
        return tools.ingredients.push(ingredient);
      });
    } else {
      tools.ingredients = [];
      tools.people = 0;
      tools.defaultPeople = 0;
    }
  }, [people, props.modalRecipe, props.open]);

  return (
    <Box
      className='recipe-people_selector'
      sx={{
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'row',
        height: '6vh',
        alignItems: 'center',
        justifyContent: 'center',
        '& > *': {
          m: 1,
        },
      }}>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          '& div': {
            padding: '0',
          },
        }}>
        <Grid {...{ xs: 3, lg: 3 }}>
          <Button
            onClick={() => changePeople('remove')}
            color={colorMinus}
            key='one'
            style={buttonStyle}
            sx={{
              borderRadius: '15px 0 0 15px',
              border: 'none',
            }}>
            <FontAwesomeIcon icon={solid('minus')} />
          </Button>
        </Grid>
        <Grid {...{ xs: 6, sm: 6, md: 4, lg: 6 }}>
          <TextField
            id='outlined-basic'
            value={`${people} personnes`}
            variant='outlined'
            size='medium'
            sx={{
              backgroundColor: '#d8dee8',
              '& fieldset': {
                borderRadius: '0',
                border: 'none',
              },
              '& input': {
                fontSize: '0.8rem',
                textAlign: 'center',
              },
              '@media (max-width: 1920px)': {
                '& input': {
                  fontSize: '0.7rem',
                },
              },
            }}
          />
        </Grid>
        <Grid {...{ xs: 3, lg: 3 }}>
          <Button
            onClick={() => changePeople('add')}
            color={colorPlus}
            key='three'
            style={buttonStyle}
            sx={{
              borderRadius: '0 15px 15px 0',
              border: 'none',
            }}>
            <FontAwesomeIcon icon={solid('plus')} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
