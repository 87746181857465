import sweet_1 from '../../../assets/icone/sweet/sweet 1.png';
import sweet_2 from '../../../assets/icone/sweet/sweet 2.png';
import sweet_3 from '../../../assets/icone/sweet/sweet 3.png';
import sweet_4 from '../../../assets/icone/sweet/sweet 4.png';
import sweet_5 from '../../../assets/icone/sweet/sweet 5.png';
import sweet_6 from '../../../assets/icone/sweet/sweet 6.png';
import sweet_7 from '../../../assets/icone/sweet/sweet 7.png';
import sweet_8 from '../../../assets/icone/sweet/sweet 8.png';
import sweet_9 from '../../../assets/icone/sweet/sweet 9.png';
import sweet_10 from '../../../assets/icone/sweet/sweet 10.png';
import sweet_11 from '../../../assets/icone/sweet/sweet 11.png';
import sweet_12 from '../../../assets/icone/sweet/sweet 12.png';
import sweet_13 from '../../../assets/icone/sweet/sweet 13.png';
import sweet_14 from '../../../assets/icone/sweet/sweet 14.png';
import sweet_15 from '../../../assets/icone/sweet/sweet 15.png';
import sweet_16 from '../../../assets/icone/sweet/sweet 16.png';
import sweet_17 from '../../../assets/icone/sweet/sweet 17.png';

const sweetenersList: Array<{ name: string; avatar_url: string; id: string }> =
  [
    {
      name: '',
      avatar_url: '',
      id: 'sweet_0',
    },
    {
      name: 'Sauce-barbecue',
      avatar_url: sweet_1,
      id: 'sweet_1',
    },
    {
      name: 'Sirop',
      avatar_url: sweet_2,
      id: 'sweet_2',
    },
    {
      name: 'Sauce-poisson',
      avatar_url: sweet_3,
      id: 'sweet_3',
    },
    {
      name: 'Miel',
      avatar_url: sweet_4,
      id: 'sweet_4',
    },
    {
      name: 'Mayonnaise',
      avatar_url: sweet_5,
      id: 'sweet_5',
    },
    {
      name: 'Moutarde',
      avatar_url: sweet_6,
      id: 'sweet_6',
    },
    {
      name: 'Sauce-soja',
      avatar_url: sweet_7,
      id: 'sweet_7',
    },
    {
      name: 'Sauce-tomate',
      avatar_url: sweet_8,
      id: 'sweet_8',
    },
    {
      name: 'Vinaigre',
      avatar_url: sweet_9,
      id: 'sweet_9',
    },
    {
      name: 'Ketchup',
      avatar_url: sweet_10,
      id: 'sweet_10',
    },
    {
      name: 'Concentrée-de-tomate',
      avatar_url: sweet_11,
      id: 'sweet_11',
    },
    {
      name: 'Coulis-de-tomate',
      avatar_url: sweet_12,
      id: 'sweet_12',
    },
    {
      name: 'Pesto',
      avatar_url: sweet_13,
      id: 'sweet_13',
    },
    {
      name: 'Vinaigre-balsamique',
      avatar_url: sweet_14,
      id: 'sweet_14',
    },
    {
      name: 'Aigre-douce',
      avatar_url: sweet_15,
      id: 'sweet_15',
    },
    {
      name: 'Viandox',
      avatar_url: sweet_16,
      id: 'sweet_16',
    },
    {
      name: 'Tabasco',
      avatar_url: sweet_17,
      id: 'sweet_17',
    },
  ];
export default sweetenersList;
