import { ReactElement } from 'react';

// style
import './Header.scss';
import logo from '../../assets/logo/logo.svg';

export default function Header(): ReactElement {
  return (
    <header className='header'>
      <div className='header__logo'>
        <img src={logo} alt='logo' />

        <h1>
          Frig<span className='center_logo'>o</span>vide
        </h1>
      </div>
    </header>
  );
}
