export default function RecipeImage({ ...props }: any) {
  return (
    <div
      style={{
        backgroundImage: `url(${props.modalRecipe.images.slice(-1)[0]})`,
        width: '100%',
        height: '100%',
        borderRadius: '1rem',
        boxShadow: '2px 2px 25px 1px rgba(0, 0, 0, 0.2)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        flexShrink: 10,
      }}
      className='imageRecipe'></div>
  );
}
