import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function RecipeAside({ ...props }: any) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '8rem',
      }}>
      <ul
        style={{
          padding: '1rem 0 0 0',
          listStyle: 'none',
          paddingLeft: '0',
        }}>
        <li
          style={{
            margin: '1rem 0 0 0',
          }}>
          <FontAwesomeIcon
            icon={regular('clock')}
            style={{
              marginRight: '1rem',
            }}
          />
          {props.modalRecipe.total_time} min
        </li>
        <li
          style={{
            margin: '1rem 0 0 0',
          }}>
          <FontAwesomeIcon
            icon={solid('kitchen-set')}
            style={{
              marginRight: '1rem',
            }}
          />
          {props.level}
        </li>
        <li
          style={{
            margin: '1rem 0 0 0',
          }}>
          <FontAwesomeIcon
            icon={solid('wallet')}
            style={{
              marginRight: '1rem',
            }}
          />
          {props.cost}
        </li>
      </ul>
    </div>
  );
}
