import { useState, useEffect } from 'react';

// tools
import Card from '@mui/material/Card';

// components
import CardsBody from './_cards/CardsBody';
import CardsHeader from './_cards/CardsHeader';
import ToolTips from '../toolTips/ToolTips';

// style
import './Cards.scss';

export default function RecipeReviewCard({ ...props }) {
  // const card start
  const [localRate, setLocalRate] = useState(0);
  const [localCommentsList, setLocalCommentsList] = useState([]);

  useEffect(() => {
    if (props.recipe.comments !== 0) {
      setLocalCommentsList(props.recipe.comments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.recipe, props.open]);

  useEffect(() => {
    if (props.recipe.length !== 0) {
      setLocalRate(props.recipe.rate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.recipe, props.open]);

  return (
    <div className='cards'>
      {props.recipe.resultTag && <ToolTips {...props} />}
      <Card
        onClick={() => {
          props.setModalRecipeId(props.recipe.id);
          props.setModalRecipe(props.recipe);
          props.setOpen(true);
        }}
        elevation={12}
        sx={{
          width: '22rem',
          height: '50vh',
          borderRadius: '20px',
          background: `linear-gradient(356deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 40%), url(${
            props.recipe.images.slice(-1)[0]
          }) center center / cover`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          margin: '1rem',
        }}>
        <CardsHeader {...props} />
        <CardsBody
          {...props}
          localRate={localRate}
          localCommentsList={localCommentsList}
        />
      </Card>
    </div>
  );
}
