import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';

// tools
import fetch from '../../service/fetch';

const buttonStyle = {
  borderRadius: '10px',
  color: 'white',
  backgroundColor: '#4bc2e4',
  '&:hover': {
    backgroundColor: '#7bedff',
  },
  fontSize: '1rem',
  fontWeight: 'bold',
  overflow: 'hidden',
  animation: 'pulse_2 1s infinite',
  '@keyframes pulse_2': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(255,149,188, 0.7)',
    },
    '70%': {
      boxShadow: '0 0 0 10px rgba(255,149,188, 0)',
    },
    '100%': {
      boxShadow: '0 0 0 0 rgba(255,149,188, 0)',
    },
  },
};

export default function Error({ ...props }) {
  const [llamaRecipes, setLlamaRecipes] = React.useState<any>({});
  const [button, setButton] = React.useState<boolean>(true);
  const [requestCount, setRequestCount] = React.useState<number>(
    localStorage.getItem('requestCount')
      ? Number(localStorage.getItem('requestCount'))
      : 0,
  );

  const getLlamaRecipes = async () => {
    if (props.search.length > 0) {
      props.setHomeLoader(true);
      setButton(false);
      const response = await fetch.llamaGenerator(props.search);
      setLlamaRecipes(response);

      if (response.message === 'Question contains symbols') {
        props.setHomeLoader(false);
        props.setOpenPopup(true);
      }

      if (response.message === 'Question not found in database') {
        // increment request count
        setRequestCount(requestCount + 1);
        localStorage.setItem('requestCount', String(requestCount + 1));
      }
    }
  };

  React.useEffect(() => {
    if (llamaRecipes.answer !== undefined) {
      props.setHomeLoader(false);
    }
  }, [llamaRecipes]);

  React.useEffect(() => {
    if (props.search.length === 0 || props.search !== llamaRecipes.params) {
      setButton(true);
      setLlamaRecipes({});
    }
  }, [props.search]);

  return (
    <div>
      {props.recipes.message === 'No recipes found' && (
        <div>
          {llamaRecipes.answer === undefined ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '60%',
                margin: 'auto',
                fontSize: '2rem',
                '@media (max-width: 490px)': { width: '90%', fontSize: '1rem' },
              }}>
              <div
                style={{
                  color: 'white',
                  textAlign: 'center',
                }}>
                Nous n'avons pas trouvé de recettes correspondant à votre
                recherche pour le moment. Mais pas de panique, notre IA peut
                vous en créer une en quelques secondes. 🤖 😍 🤗
              </div>
              <div
                style={{
                  color: 'white',
                  fontSize: '0.8rem',
                  textAlign: 'center',
                }}>
                (Fonctionnalité en cours de développement, les recettes générées
                ne sont pas encore optimisées et peuvent contenir des erreurs.
                Une limite de 3 requêtes par jour est appliquée. )
              </div>

              {requestCount < 3 ? (
                <ButtonGroup
                  sx={{
                    display: 'flex',
                    marginTop: '2rem',
                  }}
                  variant='contained'
                  aria-label='outlined primary button group'>
                  <Button
                    sx={button ? buttonStyle : { display: 'none' }}
                    onClick={getLlamaRecipes}
                    disabled={!button}>
                    Envoyer mes ingrédients
                  </Button>
                </ButtonGroup>
              ) : (
                <div
                  style={{
                    color: 'white',
                    fontSize: '2rem',
                    textAlign: 'center',
                    marginTop: '2rem',
                  }}>
                  Vous avez atteint le nombre maximum de requêtes pour
                  aujourd'hui. 🤖 😍 🤗
                </div>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '60%',
                margin: 'auto',
                '@media (max-width: 490px)': { width: '90%' },
              }}>
              <div
                style={{
                  color: 'white',
                  textAlign: 'center',
                  paddingBottom: '1rem',
                }}>
                Voici une recette générée par notre IA. 🤖 😍 🤗
              </div>
              <Paper
                elevation={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '60%',
                  height: '30rem',
                  overflow: 'auto',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  margin: 'auto',
                  padding: '1.5rem',
                  fontSize: '1.2rem',
                  '@media (max-width: 490px)': {
                    width: '95%',
                    height: '15rem',
                    fontSize: '1rem',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: '0.5rem',
                  },
                }}>
                <div
                  dangerouslySetInnerHTML={{ __html: llamaRecipes.answer }}
                  style={{
                    height: '100%',
                  }}
                />
              </Paper>
            </Box>
          )}
        </div>
      )}
      {props.recipes.message === 'Unauthorized' && props.setHomeLoader(true)}
      {props.homeLoader === true && (
        <CircularProgress
          style={{
            position: 'absolute',
            left: '45%',
            transform: 'translateX(-50%)',
            top: '35%',
            zIndex: 100,
          }}
        />
      )}
    </div>
  );
}
