import baking_1 from '../../../assets/icone/baking/baking 1.png';
import baking_2 from '../../../assets/icone/baking/baking 2.png';
import baking_3 from '../../../assets/icone/baking/baking 3.png';
import baking_4 from '../../../assets/icone/baking/baking 4.png';
import baking_5 from '../../../assets/icone/baking/baking 5.png';
import baking_6 from '../../../assets/icone/baking/baking 6.png';
import baking_7 from '../../../assets/icone/baking/baking 7.png';
import baking_8 from '../../../assets/icone/baking/baking 8.png';
import baking_9 from '../../../assets/icone/baking/baking 9.png';
import baking_10 from '../../../assets/icone/baking/baking 10.png';
import baking_11 from '../../../assets/icone/baking/baking 11.png';
import baking_12 from '../../../assets/icone/baking/baking 12.png';
import baking_13 from '../../../assets/icone/baking/baking 13.png';
import baking_14 from '../../../assets/icone/baking/baking 14.png';
import baking_15 from '../../../assets/icone/baking/baking 15.png';
import baking_16 from '../../../assets/icone/baking/baking 16.png';
import baking_17 from '../../../assets/icone/baking/baking 17.png';
import baking_18 from '../../../assets/icone/baking/baking 18.png';
import baking_19 from '../../../assets/icone/baking/baking 19.png';
import baking_20 from '../../../assets/icone/baking/baking 20.png';
import baking_21 from '../../../assets/icone/baking/baking 21.png';
import baking_22 from '../../../assets/icone/baking/baking 22.png';
import baking_23 from '../../../assets/icone/baking/baking 23.png';
import baking_24 from '../../../assets/icone/baking/baking 24.png';
import baking_25 from '../../../assets/icone/baking/baking 25.png';
import baking_26 from '../../../assets/icone/baking/baking 26.png';
import baking_27 from '../../../assets/icone/baking/baking 27.png';
import baking_28 from '../../../assets/icone/baking/baking 28.png';

const bakingList: Array<{ name: string; avatar_url: string; id: string }> = [
  {
    name: '',
    avatar_url: '',
    id: 'baking_0',
  },
  {
    name: 'Bicarbonate-de-soude',
    avatar_url: baking_1,
    id: 'baking_1',
  },
  {
    name: 'Levure',
    avatar_url: baking_2,
    id: 'baking_2',
  },
  {
    name: 'Blé',
    avatar_url: baking_3,
    id: 'baking_3',
  },
  {
    name: 'Pain',
    avatar_url: baking_4,
    id: 'baking_4',
  },
  {
    name: 'Chocolat',
    avatar_url: baking_5,
    id: 'baking_5',
  },
  {
    name: 'Cacao',
    avatar_url: baking_6,
    id: 'baking_6',
  },
  {
    name: 'Pâte',
    avatar_url: baking_7,
    id: 'baking_7',
  },
  {
    name: 'Farine',
    avatar_url: baking_8,
    id: 'baking_8',
  },
  {
    name: 'Nouilles',
    avatar_url: baking_9,
    id: 'baking_9',
  },
  {
    name: 'Pâtes-à-pizza',
    avatar_url: baking_10,
    id: 'baking_10',
  },
  {
    name: 'Riz',
    avatar_url: baking_11,
    id: 'baking_11',
  },
  {
    name: 'Quinoa',
    avatar_url: baking_12,
    id: 'baking_12',
  },
  {
    name: 'Boulghour',
    avatar_url: baking_13,
    id: 'baking_13',
  },
  {
    name: 'Pain-de-mie',
    avatar_url: baking_14,
    id: 'baking_14',
  },
  {
    name: 'Brick',
    avatar_url: baking_15,
    id: 'baking_15',
  },
  {
    name: 'Pâte-feuilletée',
    avatar_url: baking_16,
    id: 'baking_16',
  },
  {
    name: 'Pâte-brisée',
    avatar_url: baking_17,
    id: 'baking_17',
  },
  {
    name: 'Céréal',
    avatar_url: baking_18,
    id: 'baking_18',
  },
  {
    name: 'Galettes-de-riz',
    avatar_url: baking_19,
    id: 'baking_19',
  },
  {
    name: 'Pain-à-hamburger',
    avatar_url: baking_20,
    id: 'baking_20',
  },
  {
    name: 'pain-pita',
    avatar_url: baking_21,
    id: 'baking_21',
  },
  {
    name: 'Farine-complète',
    avatar_url: baking_22,
    id: 'baking_22',
  },
  {
    name: 'Farine-de-sègle',
    avatar_url: baking_23,
    id: 'baking_23',
  },
  {
    name: 'Farine-de-sarrasin',
    avatar_url: baking_24,
    id: 'baking_24',
  },
  {
    name: 'Polenta',
    avatar_url: baking_25,
    id: 'baking_25',
  },
  {
    name: 'Semoule',
    avatar_url: baking_26,
    id: 'baking_26',
  },
  {
    name: 'Café',
    avatar_url: baking_27,
    id: 'baking_27',
  },
  {
    name: 'galette',
    avatar_url: baking_28,
    id: 'baking_28',
  },
];

export default bakingList;
