import fruit_1 from '../../../assets/icone/fruit/fruit 1.png';
import fruit_2 from '../../../assets/icone/fruit/fruit 2.png';
import fruit_3 from '../../../assets/icone/fruit/fruit 3.png';
import fruit_4 from '../../../assets/icone/fruit/fruit 4.png';
import fruit_5 from '../../../assets/icone/fruit/fruit 5.png';
import fruit_6 from '../../../assets/icone/fruit/fruit 6.png';
import fruit_7 from '../../../assets/icone/fruit/fruit 7.png';
import fruit_8 from '../../../assets/icone/fruit/fruit 8.png';
import fruit_9 from '../../../assets/icone/fruit/fruit 9.png';
import fruit_10 from '../../../assets/icone/fruit/fruit 10.png';
import fruit_11 from '../../../assets/icone/fruit/fruit 11.png';
import fruit_12 from '../../../assets/icone/fruit/fruit 12.png';
import fruit_13 from '../../../assets/icone/fruit/fruit 13.png';
import fruit_14 from '../../../assets/icone/fruit/fruit 14.png';
import fruit_15 from '../../../assets/icone/fruit/fruit 15.png';
import fruit_16 from '../../../assets/icone/fruit/fruit 16.png';
import fruit_17 from '../../../assets/icone/fruit/fruit 17.png';
import fruit_18 from '../../../assets/icone/fruit/fruit 18.png';
import fruit_19 from '../../../assets/icone/fruit/fruit 19.png';
import fruit_20 from '../../../assets/icone/fruit/fruit 20.png';
import fruit_22 from '../../../assets/icone/fruit/fruit 22.png';
import fruit_23 from '../../../assets/icone/fruit/fruit 23.png';
import fruit_24 from '../../../assets/icone/fruit/fruit 24.png';
import fruit_25 from '../../../assets/icone/fruit/fruit 25.png';
import fruit_26 from '../../../assets/icone/fruit/fruit 26.png';
import fruit_27 from '../../../assets/icone/fruit/fruit 27.png';
import fruit_28 from '../../../assets/icone/fruit/fruit 28.png';
import fruit_29 from '../../../assets/icone/fruit/fruit 29.png';
import fruit_30 from '../../../assets/icone/fruit/fruit 30.png';
import fruit_31 from '../../../assets/icone/fruit/fruit 31.png';
import fruit_33 from '../../../assets/icone/fruit/fruit 33.png';
import fruit_34 from '../../../assets/icone/fruit/fruit 34.png';
import fruit_35 from '../../../assets/icone/fruit/fruit 35.png';
import fruit_36 from '../../../assets/icone/fruit/fruit 36.png';
import fruit_37 from '../../../assets/icone/fruit/fruit 37.png';
import fruit_38 from '../../../assets/icone/fruit/fruit 38.png';
import fruit_39 from '../../../assets/icone/fruit/fruit 39.png';
import fruit_40 from '../../../assets/icone/fruit/fruit 40.png';
import fruit_41 from '../../../assets/icone/fruit/fruit 41.png';

const FruitsList: Array<{ name: string; avatar_url: string; id: string }> = [
  {
    name: '',
    avatar_url: '',
    id: 'fruit_0',
  },
  {
    name: 'Pomme',
    avatar_url: fruit_1,
    id: 'fruit_1',
  },
  {
    name: 'Banane',
    avatar_url: fruit_2,
    id: 'fruit_2',
  },
  {
    name: 'Mûres',
    avatar_url: fruit_3,
    id: 'fruit_3',
  },
  {
    name: 'Myrtilles',
    avatar_url: fruit_4,
    id: 'fruit_4',
  },
  {
    name: 'Cerises',
    avatar_url: fruit_5,
    id: 'fruit_5',
  },
  {
    name: 'Noix-de-coco',
    avatar_url: fruit_6,
    id: 'fruit_6',
  },
  {
    name: 'Cranberries',
    avatar_url: fruit_7,
    id: 'fruit_7',
  },
  {
    name: 'Raisins',
    avatar_url: fruit_8,
    id: 'fruit_8',
  },
  {
    name: 'Kiwi',
    avatar_url: fruit_9,
    id: 'fruit_9',
  },
  {
    name: 'Citron',
    avatar_url: fruit_10,
    id: 'fruit_10',
  },
  {
    name: 'Citron-vert',
    avatar_url: fruit_11,
    id: 'fruit_11',
  },
  {
    name: 'Mangue',
    avatar_url: fruit_12,
    id: 'fruit_12',
  },
  {
    name: 'Orange',
    avatar_url: fruit_13,
    id: 'fruit_13',
  },
  {
    name: 'Pêche',
    avatar_url: fruit_14,
    id: 'fruit_14',
  },
  {
    name: 'Poire',
    avatar_url: fruit_15,
    id: 'fruit_15',
  },
  {
    name: 'Ananas',
    avatar_url: fruit_16,
    id: 'fruit_16',
  },
  {
    name: 'Framboises',
    avatar_url: fruit_17,
    id: 'fruit_17',
  },
  {
    name: 'Fraises',
    avatar_url: fruit_18,
    id: 'fruit_18',
  },
  {
    name: 'Pastèque',
    avatar_url: fruit_19,
    id: 'fruit_19',
  },
  {
    name: 'Cassis',
    avatar_url: fruit_20,
    id: 'fruit_20',
  },
  {
    name: 'Clémentine',
    avatar_url: fruit_22,
    id: 'fruit_22',
  },
  {
    name: 'Coing',
    avatar_url: fruit_23,
    id: 'fruit_23',
  },
  {
    name: 'Datte',
    avatar_url: fruit_24,
    id: 'fruit_24',
  },
  {
    name: 'Figue',
    avatar_url: fruit_25,
    id: 'fruit_25',
  },
  {
    name: 'Fruit-de-la-passion',
    avatar_url: fruit_26,
    id: 'fruit_26',
  },
  {
    name: 'Grenade',
    avatar_url: fruit_27,
    id: 'fruit_27',
  },
  {
    name: 'Groseille',
    avatar_url: fruit_28,
    id: 'fruit_28',
  },
  {
    name: 'Kaki',
    avatar_url: fruit_29,
    id: 'fruit_29',
  },
  {
    name: 'Litchi',
    avatar_url: fruit_30,
    id: 'fruit_30',
  },
  {
    name: 'Mandarine',
    avatar_url: fruit_31,
    id: 'fruit_31',
  },
  {
    name: 'Melon',
    avatar_url: fruit_33,
    id: 'fruit_33',
  },
  {
    name: 'Mirabelle',
    avatar_url: fruit_34,
    id: 'fruit_34',
  },
  {
    name: 'Nectarine',
    avatar_url: fruit_35,
    id: 'fruit_35',
  },
  {
    name: 'Pamplemousse',
    avatar_url: fruit_36,
    id: 'fruit_36',
  },
  {
    name: 'Papaye',
    avatar_url: fruit_37,
    id: 'fruit_37',
  },
  {
    name: 'Prune',
    avatar_url: fruit_38,
    id: 'fruit_38',
  },
  {
    name: 'Quetsche',
    avatar_url: fruit_39,
    id: 'fruit_39',
  },
  {
    name: 'Reine-claude',
    avatar_url: fruit_40,
    id: 'fruit_40',
  },
  {
    name: 'Abricot',
    avatar_url: fruit_41,
    id: 'fruit_41',
  },
];
export default FruitsList;
