import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { pink } from '@mui/material/colors';

// ingredients list
import bakingList from './ingredientsTypeList/bakingList';
import dairyList from './ingredientsTypeList/dairyList';
import FruitsList from './ingredientsTypeList/fruitsList';
import LiquidsList from './ingredientsTypeList/liquidsList';
import meatList from './ingredientsTypeList/meatsList';
import NutsList from './ingredientsTypeList/nutsList';
import SeafoodsList from './ingredientsTypeList/seafoodsList';
import sweetenersList from './ingredientsTypeList/sweetenersList';
import vegetablesList from './ingredientsTypeList/vegetablesList';
import spicesList from './ingredientsTypeList/spicesList';
import cheeseList from './ingredientsTypeList/cheeseList';

// Parametres list
import paramsList from './ingredientsTypeList/paramsList';
import Params from '../../assets/icone/params/params_3.png';

// tools
import tools from '../../service/tools';
import { Box } from '@mui/system';

// icones
import Alcohol from '../../assets/icone/ingredients_icones/Alcohol.png';
import Baking from '../../assets/icone/ingredients_icones/Baking.png';
import Fruits from '../../assets/icone/ingredients_icones/Fruits.png';
import Condiments from '../../assets/icone/ingredients_icones/Condiments.png';
import Meats from '../../assets/icone/ingredients_icones/Meats.png';
import Nuts from '../../assets/icone/ingredients_icones/Nuts.png';
import Seafoods from '../../assets/icone/ingredients_icones/Seafood.png';
import Spices from '../../assets/icone/ingredients_icones/Spices.png';
import Vegetables from '../../assets/icone/ingredients_icones/Vegetables.png';
import Cheese from '../../assets/icone/ingredients_icones/Cheese.png';
import Milk from '../../assets/icone/ingredients_icones/Milk.png';

const label = { inputProps: { 'aria-label': 'Checkbox aliments' } };

const list: any = [
  {
    name: 'Produits laitiers',
    avatar_url: Milk,
    list: dairyList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
  {
    name: 'Legumes',
    avatar_url: Vegetables,
    list: vegetablesList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
  {
    name: 'Fruits',
    avatar_url: Fruits,
    list: FruitsList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
  {
    name: 'Céréales',
    avatar_url: Baking,
    list: bakingList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
  {
    name: 'Condiments',
    avatar_url: Condiments,
    list: sweetenersList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
  {
    name: 'Viandes',
    avatar_url: Meats,
    list: meatList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
  {
    name: 'Produits de la mer',
    avatar_url: Seafoods,
    list: SeafoodsList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
  {
    name: 'Liquides',
    avatar_url: Alcohol,
    list: LiquidsList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
  {
    name: 'Fruits à coque',
    avatar_url: Nuts,
    list: NutsList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
  {
    name: 'Epices',
    avatar_url: Spices,
    list: spicesList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
  {
    name: 'Fromages',
    avatar_url: Cheese,
    list: cheeseList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
  {
    name: 'Paramètres',
    avatar_url: Params,
    list: paramsList.sort((a: any, b: any) => a.name.localeCompare(b.name)),
  },
];

// order list by name alphabetically but put params at the end
list.sort((a: any, b: any) => {
  if (b.name === 'Paramètres') {
    return 1;
  } else if (a.name === 'Paramètres') {
    return -1;
  } else {
    return a.name.localeCompare(b.name);
  }
});

export default function ControlledAccordions({ ...props }: any) {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);

      setTimeout(() => {
        const panelCurrent = document.querySelector(`.${panel}`);
        panelCurrent?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
    };

  return (
    <List
      sx={{
        width: '100%',
        maxHeight: 600,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        '& ul': { padding: 0 },
      }}
      subheader={<li />}>
      {list.map((item: any, index: number) => {
        return (
          <li
            key={tools.generateKey()}
            style={{ listStyle: 'none' }}
            onChange={() => window.scrollTo(0, 0)}>
            <ul key={tools.generateKey()}>
              <ListSubheader
                key={tools.generateKey()}
                sx={{
                  fontSize: '1.4rem',
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: 26,
                  backgroundColor: '#f5f5f5',
                  margin: '0 1rem 0 1rem',
                }}>
                <img
                  key={tools.generateKey()}
                  src={item.avatar_url}
                  alt={item.name}
                  style={{ width: 30, height: 'auto', marginRight: 10 }}
                />
                {item.name}
              </ListSubheader>
              <Accordion
                elevation={0}
                key={tools.generateKey()}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{ backgroundColor: '#f5f5f5', marginBottom: '1rem' }}>
                <AccordionSummary
                  key={tools.generateKey()}
                  sx={{
                    width: '100%',
                    position: 'absolute',
                    zIndex: 2,
                    top: '-3.3rem',
                    right: '0',
                  }}
                  expandIcon={
                    <ExpandMoreIcon sx={{ height: 'auto', width: '2.5rem' }} />
                  }
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                />

                <AccordionDetails
                  className={`panel${index}`}
                  sx={{ backgroundColor: '#fff' }}
                  key={tools.generateKey()}>
                  {item.list.map((item: any, index: number) => {
                    return (
                      <Box
                        key={tools.generateKey()}
                        sx={{
                          width: '90%',
                          margin: '0 auto',
                        }}>
                        <ListItem
                          divider={true}
                          key={tools.generateKey()}
                          disablePadding
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}>
                          <div
                            className='container_image_foods'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '3rem',
                              height: '2rem',
                            }}>
                            <img
                              key={tools.generateKey()}
                              src={item.avatar_url}
                              alt={item.name}
                              style={{
                                width: 'auto',
                                height: '2rem',
                              }}
                            />
                          </div>
                          <ListItemText
                            key={tools.generateKey()}
                            primary={item.name.replace(/-/g, ' ')}
                            sx={{ marginLeft: '1rem' }}
                          />
                          {item.name.length > 0 ? (
                            <Checkbox
                              {...label}
                              size='medium'
                              sx={{
                                color: pink[800],
                                '&.Mui-checked': {
                                  color: pink[200],
                                },
                              }}
                              key={tools.generateKey()}
                              name={item.name}
                              id={item.id}
                              onChange={(e) => props.handleCheck(e)}
                              checked={
                                props.virtualFrigo.find(
                                  (data: any) => data.name === item.name,
                                )?.checked
                              }
                            />
                          ) : null}
                        </ListItem>
                      </Box>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </ul>
          </li>
        );
      })}
    </List>
  );
}
