import { useState, useEffect } from 'react';

// tools
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

// components
import RecipeAside from './RecipeAside';
import RecipePeople from './RecipePeople';
import Rating from '../../ratingModal/Rating';

// nutriscore
import A from '../../../assets/nutriscore/nutri-score-A.svg.png';
import B from '../../../assets/nutriscore/nutri-score-B.svg.png';
import C from '../../../assets/nutriscore/nutri-score-C.svg.png';
import D from '../../../assets/nutriscore/nutri-score-D.svg.png';
import E from '../../../assets/nutriscore/nutri-score-E.svg.png';

const style = {
  height: '3rem',
  width: '6rem',
};

export default function RecipeTitle({ ...props }) {
  const [level, setLevel] = useState('');
  const [cost, setCost] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const levelSetting = () => {
    switch (props.modalRecipe.difficulty) {
      case '1':
        setLevel('Très facile');
        break;
      case '2':
        setLevel('Facile');
        break;
      case '3':
        setLevel('Moyen');
        break;
      case '4':
        setLevel('Difficile');
        break;
      default:
        setLevel('Très facile');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const costSetting = () => {
    switch (props.modalRecipe.budget) {
      case '1':
        setCost('Bon marché');
        break;
      case '2':
        setCost('Abordable');
        break;
      case '3':
        setCost('Coûteux');
        break;
      default:
        setCost('Bon marché');
    }
  };

  useEffect(() => {
    levelSetting();
    costSetting();
  }, [costSetting, levelSetting, props.open]);

  return (
    <div
      style={{
        margin: '0 0 1rem 1rem',
      }}>
      <Typography
        id='keep-mounted-modal-title'
        variant='h6'
        component='h2'
        style={{ fontSize: '1.8rem', fontWeight: '600' }}>
        {props.modalRecipe.name.charAt(0).toUpperCase() +
          props.modalRecipe.name.slice(1)}

        <span
          style={{
            fontSize: '0.8rem',
            fontWeight: '400',
            fontStyle: 'italic',
            color: '#757575',
            display: 'block',
          }}>
          <br />
          par {props.modalRecipe.author}
        </span>
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Rating {...props} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
            '@media (max-width: 600px)': {
              display: 'none',
            },
          }}
          className='number_comments_header'>
          <p
            style={{
              margin: '0 1rem 0 0',
              color: '#4dabf5',
              fontWeight: '600',
            }}>
            {props.listOfComments === undefined
              ? 0
              : props.listOfComments.length}{' '}
            avis
          </p>
          <FontAwesomeIcon
            icon={solid('chevron-right')}
            style={{
              color: '#4dabf5',
              margin: '0 0 0 1rem',
              fontSize: '1.6rem',
              transition: 'transform 0.3s ease',
              transform: props.comment ? 'rotateY(-180deg)' : 'rotateY(0deg)',
              cursor: 'pointer',
            }}
            onClick={() => props.openComment()}
          />
        </Box>
      </div>
      <Box
        sx={{
          flexGrow: 1,
          p: 0,
          width: '100%',
        }}>
        {props.modalRecipe.length !== 0 &&
          (props.modalRecipe.nutriments.nutriscore === 'A' ? (
            <img src={A} alt='nutriscore A' style={style} />
          ) : props.modalRecipe.nutriments.nutriscore === 'B' ? (
            <img src={B} alt='nutriscore B' style={style} />
          ) : props.modalRecipe.nutriments.nutriscore === 'C' ? (
            <img src={C} alt='nutriscore C' style={style} />
          ) : props.modalRecipe.nutriments.nutriscore === 'D' ? (
            <img src={D} alt='nutriscore D' style={style} />
          ) : props.modalRecipe.nutriments.nutriscore === 'E' ? (
            <img src={E} alt='nutriscore E' style={style} />
          ) : (
            ''
          ))}
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            paddingTop: '0',
          }}>
          <Grid {...{ xs: 12, sm: 12, md: 5, lg: 6 }}>
            <RecipeAside level={level} cost={cost} {...props} />
          </Grid>
          <Grid
            {...{ xs: 12, sm: 12, md: 7, lg: 6 }}
            sx={{
              '@media (max-width: 600px)': {
                display: 'none',
              },
            }}>
            <RecipePeople {...props} />
          </Grid>
          <Grid
            {...{ xs: 12, sm: 12, md: 7, lg: 6 }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '0',
              justifyContent: 'center',
              '@media (min-width: 600px)': {
                display: 'none',
              },
            }}
            className='number_comments_header'>
            <p
              style={{
                margin: '0',
                color: '#4dabf5',
                fontWeight: '600',
              }}>
              {props.listOfComments === undefined
                ? 0
                : props.listOfComments.length}{' '}
              avis
            </p>
            <FontAwesomeIcon
              icon={solid('chevron-right')}
              style={{
                color: '#4dabf5',
                margin: '0 0 0 1rem',
                fontSize: '1.6rem',
                transition: 'transform 0.3s ease',
                transform: props.comment ? 'rotateY(-180deg)' : 'rotateY(0deg)',
                cursor: 'pointer',
              }}
              onClick={() => props.openComment()}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
