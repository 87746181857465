import { useState, useEffect } from 'react';

// tools
import Collapse from '@mui/material/Collapse';

// components
import Comments from './Comments';

export default function SimpleCollapse({ ...props }) {
  const [imageSize, setImageSize] = useState<undefined | number>(0);

  useEffect(() => {
    function handleResize() {
      setImageSize(document.querySelector('.imageRecipe')?.clientWidth);
    }
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    setImageSize(document.querySelector('.imageRecipe')?.clientWidth);
  }, [props.open]);

  return (
    <Collapse
      orientation='horizontal'
      in={props.comment}
      collapsedSize={0}
      style={{ flexShrink: 0 }}>
      <div
        style={{
          width: imageSize,
          height: '100%',
        }}>
        <Comments {...props} />
      </div>
    </Collapse>
  );
}
