const api = {
  url: 'https://api.frigovide.com',
  // url: 'http://localhost:3000',

  startSession: async () => {
    const response = await fetch(`${api.url}/startSession`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: 'app_react',
        password: 'tarte au kouech',
      }),
    });
    const data = await response.json();
    return data;
  },

  getAllRecipes: async () => {
    const response = await fetch(`${api.url}/recipes/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    });
    return response.json();
  },

  getLastRecipes: async () => {
    const response: any = await fetch(`${api.url}/recipes/last`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    });
    return response.json();
  },

  getAlphabeticalRecipes: async (letter: string) => {
    const response = await fetch(`${api.url}/recipes/alphabetical/${letter}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    });
    return response.json();
  },

  getRecipeByIA: async (search: string, mode: string, params: any) => {
    const response = await fetch(`${api.url}/recipes/search/${mode}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        search,
        params,
      }),
    });
    return response.json();
  },

  getRecipeById: async (id: number) => {
    const response = await fetch(`${api.url}/recipes/one/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    });
    return response.json();
  },

  addComment: async (id: number, data: object) => {
    const response = await fetch(`${api.url}/comments/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  },

  getComments: async (id: number) => {
    const response = await fetch(`${api.url}/comments/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    });
    if (response.status === 200) {
      return response.json();
    }
  },

  getAllComments: async () => {
    const response = await fetch(`${api.url}/comments/all`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    });
    return response.json();
  },

  patchRecipeRate: async (id: number, rate: number) => {
    const response = await fetch(`${api.url}/recipes/rate/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ rate }),
    });
    return response;
  },

  llamaGenerator: async (data: any) => {
    const response = await fetch(`${api.url}/llama/generate/${data}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    });
    return response.json();
  },
};

export default api;
