export default function RecipeFooter({ ...props }: any) {
  return (
    <div
      className='footer'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        bottom: '0',
      }}>
      <p
        style={{
          textAlign: 'center',
          fontSize: '0.8rem',
          margin: '0',
        }}>
        Recette provenant de{' '}
        <a
          className='origine_url'
          href={props.modalRecipe.url}
          target='_blank'
          rel='noreferrer'>
          marmiton
        </a>
      </p>
    </div>
  );
}
