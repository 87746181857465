/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

// tools
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// icons
import KitchenRoundedIcon from '@mui/icons-material/KitchenRounded';
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded';

// style
import './Search.scss';

// components
import Fridge from '../fridge/Fridge';

export default function Search({ ...props }) {
  const [search, setSearch] = useState('');

  const twoCalls = (e: any) => {
    setSearch(e.target.value);
    props.setHomeLoader(true);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      props.setHomeLoader(true);
      props.getRecipeBySearch(search);
      e.target.blur();
    }
  };

  useEffect(() => {
    if (search.length >= 1 && props.token.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        props.getRecipeBySearch(search);

        // change time value to change wait time
      }, 3000);

      return () => clearTimeout(delayDebounceFn);
    } else if (search.length === 0 && props.token.length > 0) {
      props.setHomeLoader(true);
      props.getRecipeBySearch('');
    }
  }, [search]);

  return (
    <Box className='searchField'>
      <Fridge {...props} />
      <Grid
        container
        spacing={0}
        sx={{
          justifyContent: 'center',
          flexWrap: 'nowrap',
          maxWidth: '60%',
          margin: '0 auto',
          '@media (max-width: 800px)': {
            maxWidth: '90%',
          },
          '@media (max-width: 500px)': {
            maxWidth: '90%',
          },
        }}>
        <Grid {...{ lg: 1 }}>
          <Button
            variant='contained'
            className='fridge'
            onClick={() => props.fridge.handleOpenFridge()}
            sx={{
              color: 'white',
              borderRadius: '10px 10px 0 0',
              overflow: 'hidden',
              animation: 'pulse_2 1s infinite',
              '@keyframes pulse_2': {
                '0%': {
                  boxShadow: '0 0 0 0 rgba(255,149,188, 0.7)',
                },
                '70%': {
                  boxShadow: '0 0 0 10px rgba(255,149,188, 0)',
                },
                '100%': {
                  boxShadow: '0 0 0 0 rgba(255,149,188, 0)',
                },
              },
            }}>
            <KitchenRoundedIcon />
          </Button>
        </Grid>
        <Grid
          {...{ lg: 8 }}
          sx={{
            '@media (max-width: 1200px)': {
              width: '100%',
            },
            '@media (max-width: 800px)': {
              width: '100%',
            },
            '@media (max-width: 500px)': {
              width: '60%',
            },
          }}>
          <TextField
            onChange={(e) => twoCalls(e)}
            onKeyPress={(e) => handleKeyPress(e)}
            value={search}
            fullWidth
            id='input_search'
            label='Rechercher une recette, un ingrédient...'
            variant='outlined'
          />
        </Grid>
        <Grid {...{ lg: 1 }}>
          <Button
            className='clear'
            variant='contained'
            type='submit'
            onClick={() => {
              setSearch('');
            }}>
            <BackspaceRoundedIcon />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
