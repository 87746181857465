import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function CGU() {
  const [color, setColor] = useState('#4bc2e4');

  return (
    <Box component='main'>
      <Box
        className='container'
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Paper
          elevation={9}
          sx={{
            p: 10,
            m: 10,
            '@media (max-width: 600px)': {
              m: 1,
              p: 2,
              paddingTop: 3,
              marginTop: 10,
            },
          }}>
          <Box
            sx={{
              position: 'sticky',
              right: '1.5rem',
              top: '1rem',
              textAlign: 'right',
            }}>
            <FontAwesomeIcon
              style={{
                fontSize: '3rem',
                color: color,
                top: '0',
                right: '0',
                cursor: 'pointer',
                transition: 'color 0.3s ease',
                zIndex: '100',
                margin: '0 0 0 auto',
              }}
              className='card_cross print_cursor'
              icon={faXmark}
              onClick={() => {
                window.location.href = '/';
              }}
              onMouseEnter={() => {
                setColor('#619DDA');
              }}
              onMouseLeave={() => {
                setColor('#4bc2e4');
              }}
            />
          </Box>
          <h1>Conditions Générales d'Utilisation</h1>
          <h5>En vigueur au 01/09/2022</h5>
          <p>
            Les présentes conditions générales d'utilisation (dites « CGU ») ont
            pour objet l'encadrement juridique des modalités de mise à
            disposition du site et des services par Webplayground et de définir
            les conditions d’accès et d’utilisation des services par «
            l'Utilisateur ».{' '}
            <a href='/cgu'>
              Les présentes CGU sont accessibles sur le site à la rubrique
              «CGU».
            </a>
          </p>
          <h2>ARTICLE 1 : Les mentions légales</h2>
          <p>
            L'édition du site frigovide.com est assurée par l'entreprise
            Webplayground,{' '}
            <ul>
              <li>immatriculée sous le numéro 511749210,</li>{' '}
              <li>dont le siège social est situé à Vingrau,</li>{' '}
              <li>Numéro de téléphone 0626552797,</li>{' '}
              <li>Adresse e-mail : contact@webplayground.fr.</li>
            </ul>
            L'hébergeur du site frigovide.com est la société OVH cloud,
            <ul>
              <li>dont le siège social est situé à Roubaix,</li>
              <li>avec le numéro de téléphone : 1007.</li>
            </ul>
          </p>
          <h2>ARTICLE 2 : Accès au site</h2>
          <p>
            Le site frigovide.com permet à l'Utilisateur un accès gratuit aux
            services suivants :
            <ul>
              <li>Consultation recettes de cuisine,</li>{' '}
              <li>ajout de commentaires,</li>
              <li>ajout d'évaluation</li>
            </ul>
            Le site est accessible gratuitement en tout lieu à tout Utilisateur
            ayant un accès à Internet. Tous les frais supportés par
            l'Utilisateur pour accéder au service (matériel informatique,
            logiciels, connexion Internet, etc.) sont à sa charge.
          </p>
          <h2>ARTICLE 3 : Collecte des données</h2>
          <p>
            Le site assure à l'Utilisateur une collecte et un traitement
            d'informations personnelles dans le respect de la vie privée
            conformément à la loi n°78-17 du 6 janvier 1978 relative à
            l'informatique, aux fichiers et aux libertés. En vertu de la loi
            Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur
            dispose d'un droit d'accès, de rectification, de suppression et
            d'opposition de ses données personnelles. L'Utilisateur exerce ce
            droit : · par mail à l'adresse email contact@webplayground.fr﻿
          </p>
          <h2>ARTICLE 4 : Propriété intellectuelle</h2>
          <p>
            Les marques, logos, signes ainsi que tous les contenus du site
            (textes, images, son…) font l'objet d'une protection par le Code de
            la propriété intellectuelle et plus particulièrement par le droit
            d'auteur. L'Utilisateur doit solliciter l'autorisation préalable du
            site pour toute reproduction, publication, copie des différents
            contenus. Il s'engage à une utilisation des contenus du site dans un
            cadre strictement privé, toute utilisation à des fins commerciales
            et publicitaires est strictement interdite. Toute représentation
            totale ou partielle de ce site par quelque procédé que ce soit, sans
            l’autorisation expresse de l’exploitant du site Internet
            constituerait une contrefaçon sanctionnée par l’article L 335-2 et
            suivants du Code de la propriété intellectuelle. Il est rappelé
            conformément à l’article L122-5 du Code de propriété intellectuelle
            que l’Utilisateur qui reproduit, copie ou publie le contenu protégé
            doit citer l’auteur et sa source.
          </p>
          <h2>ARTICLE 5 : Responsabilité et force majeure</h2>
          <p>
            Les sources des informations diffusées sur le site frigovide.com
            sont réputées fiables mais le site ne garantit pas qu’il soit exempt
            de défauts, d’erreurs ou d’omissions. Les informations communiquées
            sont présentées à titre indicatif et général sans valeur
            contractuelle. Malgré des mises à jour régulières, le site
            frigovide.com ne peut être tenu responsable de la modification des
            dispositions administratives et juridiques survenant après la
            publication. De même, le site ne peut être tenue responsable de
            l’utilisation et de l’interprétation de l’information contenue dans
            ce site. Le site frigovide.com ne peut être tenu pour responsable
            d’éventuels virus qui pourraient infecter l’ordinateur ou tout
            matériel informatique de l’Internaute, suite à une utilisation, à
            l’accès, ou au téléchargement provenant de ce site. La
            responsabilité du site ne peut être engagée en cas de force majeure
            ou du fait imprévisible et insurmontable d'un tiers.
          </p>
          <ul>
            <li>
              <h3>ARTICLE 5.1 : Recettes</h3>
              <ul>
                <li>
                  Frigovide.com ne garantit pas le résultat des recettes
                  diffusées sur son site, ni leur qualité.
                </li>
                <li>
                  Frigovide.com ne garantit pas que les recettes répondront aux
                  attentes des internautes ou que leurs résultats seront exacts
                  et fiables.
                </li>
              </ul>
              <p>
                En conséquence, la responsabilité de frigovide.com ne saurait
                être engagée en cas d'erreur ou d'omission dans l'une de ces
                recettes, textes, informations ou illustrations diffusées sur
                son site.
              </p>
            </li>
            <li>
              <h3>ARTICLE 5.2 : Nutriscore</h3>
              <p>
                Frigovide.com ne garantit pas que les nutriscores calculés sur
                son site soient exacts et fiables. Ils sont calculés à partir
                des informations fournies par les internautes. Ils doivent être
                considérés comme des indications et non comme des valeurs
                exactes.
              </p>
            </li>
          </ul>
          <h2>ARTICLE 6 : Liens hypertextes</h2>
          <p>
            Des liens hypertextes peuvent être présents sur le site.
            L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira
            du site frigovide.com. Ce dernier n’a pas de contrôle sur les pages
            web sur lesquelles aboutissent ces liens et ne saurait, en aucun
            cas, être responsable de leur contenu.
          </p>
          <h2>ARTICLE 7 : Cookies</h2>
          <p>
            L’Utilisateur est informé que lors de ses visites sur le site, un
            cookie peut s’installer automatiquement sur son logiciel de
            navigation. Les cookies sont de petits fichiers stockés
            temporairement sur le disque dur de l’ordinateur de l’Utilisateur
            par votre navigateur et qui sont nécessaires à l’utilisation du site
            frigovide.com. Les cookies ne contiennent pas d’information
            personnelle et ne peuvent pas être utilisés pour identifier
            quelqu’un. Un cookie contient un identifiant unique, généré
            aléatoirement et donc anonyme. Certains cookies expirent à la fin de
            la visite de l’Utilisateur, d’autres restent. L’information contenue
            dans les cookies est utilisée pour améliorer le site frigovide.com.
            L’Utilisateur pourra désactiver ces cookies par l’intermédiaire du
            lien présent dans le pied de page du site frigovide.com.
          </p>
          <h2>ARTICLE 8 : Publication par l’Utilisateur</h2>
          <p>
            Le site permet aux membres de publier les contenus suivants :
            commentaires / évaluation. Dans ses publications, le membre s’engage
            à respecter les règles de la Netiquette (règles de bonne conduite de
            l’internet) et les règles de droit en vigueur. Le site peut exercer
            une modération sur les publications et se réserve le droit de
            refuser leur mise en ligne, sans avoir à s’en justifier auprès du
            membre. Le membre reste titulaire de l’intégralité de ses droits de
            propriété intellectuelle. Mais en publiant une publication sur le
            site, il cède à la société éditrice le droit non exclusif et gratuit
            de représenter, reproduire, adapter, modifier, diffuser et
            distribuer sa publication, directement ou par un tiers autorisé,
            dans le monde entier, sur tout support (numérique ou physique), pour
            la durée de la propriété intellectuelle. Le Membre cède notamment le
            droit d'utiliser sa publication sur internet et sur les réseaux de
            téléphonie mobile. La société éditrice s'engage à faire figurer le
            nom du membre à proximité de chaque utilisation de sa publication.
            Tout contenu mis en ligne par l'Utilisateur est de sa seule
            responsabilité. L'Utilisateur s'engage à ne pas mettre en ligne de
            contenus pouvant porter atteinte aux intérêts de tierces personnes.
            Tout recours en justice engagé par un tiers lésé contre le site sera
            pris en charge par l'Utilisateur. Le contenu de l'Utilisateur peut
            être à tout moment et pour n'importe quelle raison supprimé ou
            modifié par le site, sans préavis.
          </p>
          <h2>ARTICLE 9 : Droit applicable et juridiction compétente</h2>
          <p>
            La législation française s'applique au présent contrat. En cas
            d'absence de résolution amiable d'un litige né entre les parties,
            les tribunaux français seront seuls compétents pour en connaître.
            Pour toute question relative à l’application des présentes CGU, vous
            pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
          </p>

          <h2>ARTICLE 10 : Limitation d'utilisation de l'IA générationnelle</h2>
          <ul>
            <li>
              La fonctionnalité d'IA générationnelle est fournie à titre gratuit
              aux utilisateurs du site pour la création de recettes de cuisine
              lorsque celles-ci ne sont pas disponibles dans la base de données
              du site.
            </li>
            <li>
              Les utilisateurs sont limités à trois (3) requêtes par jour pour
              l'utilisation de cette fonctionnalité.
            </li>
            <li>
              Les requêtes sont comptabilisées en fonction de l'adresse IP de
              l'utilisateur et sont réinitialisées par tranche de vingt-quatre
              (24) heures glissantes.
            </li>
            <li>
              En cas de dépassement de cette limite, l'utilisateur sera empêché
              d'utiliser la fonctionnalité d'IA générationnelle pendant une
              durée de vingt-quatre (24) heures.
            </li>
            <li>
              L'utilisateur est informé de cette limitation au moment de
              l'utilisation de la fonctionnalité d'IA générationnelle et accepte
              donc les conditions d'utilisation décrites dans cette section.
            </li>
            <li>
              Le site se réserve le droit de modifier ces conditions
              d'utilisation à tout moment, sans préavis et à sa seule
              discrétion.
            </li>
            <li>
              En utilisant la fonctionnalité d'IA générationnelle, l'utilisateur
              accepte les présentes conditions d'utilisation et s'engage à les
              respecter. En cas de non-respect de ces conditions, le site se
              réserve le droit de prendre des mesures disciplinaires, y compris
              le blocage de l'accès à la fonctionnalité d'IA générationnelle
              voir même le blocage de l'accès au site.
            </li>
          </ul>

          <p>
            Nous vous recommandons de lire attentivement les conditions
            d'utilisation avant d'utiliser la fonctionnalité d'IA
            générationnelle. Si vous avez des questions ou des préoccupations,
            n'hésitez pas à contacter notre service clientèle.
          </p>

          <a href='/'>Revenir à la page d'accueil</a>
        </Paper>
      </Box>
    </Box>
  );
}
