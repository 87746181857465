import { useState, useEffect } from 'react';

// tools
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

// components
import RecipeGrid from './RecipeGrid';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
  width: 0.95,
  height: 0.95,
  borderRadius: '20px',
  overflow: 'auto',
  background:
    'linear-gradient(243deg, rgba(255,248,248,1) 0%, rgba(224,220,220,1) 100%)',
};

export default function KeepMountedModal({ ...props }) {
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleClose = () => {
    setOpen(false);
    props.setModalRecipe([]);
    props.setOpen(false);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <div>
      <Modal
        sx={{
          height: '100%',
          minHeight: '100%',
          transition: 'all 0.5s ease-in-out',
          opacity: open ? 1 : 0,
        }}
        disableAutoFocus={true}
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby='keep-mounted-modal-title'
        aria-describedby='keep-mounted-modal-description'>
        <Box sx={style}>
          {props.modalRecipe.length !== 0 && (
            <RecipeGrid
              {...props}
              handleClose={handleClose}
              handleToggle={handleToggle}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
