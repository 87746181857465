import { useState, useEffect, forwardRef, ReactElement, Ref } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import cookies from '../../assets/logo/cookies.png';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AlertDialogSlide({ ...props }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.openCookies);
  }, [props.openCookies]);

  return (
    <div>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#f5f5f5',
            maxWidth: '760px',
          },
        }}
        onClose={() => {
          props.disallow();
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby='alert-dialog-slide-description'>
        <DialogTitle>{'Nous mangeons des cookies...'}</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            '@media (max-width: 550px)': {
              flexDirection: 'column',
            },
          }}>
          <DialogContentText id='alert-dialog-slide-description'>
            Pour améliorer votre expérience utilisateur et mesurer l’audience de
            notre site, frigovide.com utilise des cookies.
            <br />
            <span
              style={{
                color: '#aab1bd',
                textDecoration: 'underline',
              }}>
              Ils sont détaillés ci-dessous :
            </span>{' '}
            <br />
            Mesure d’Audience : Le site frigovide.com utilisent des traceurs
            pour mesurer l’audience. <br />
            Session : Le site frigovide.com utilise des cookies de session pour
            vous identifier lors de votre navigation. <br />
            (il sont obligatoires pour le bon fonctionnement du site) <br />
            Nous recueillons aussi les emails pour l'ajout de commentaire afin
            de limiter les spams. <br />
            En savoir plus : Toute inaction de votre part vaudra refus de
            consentement au dépôt de cookies présentée ci-dessus. Vous pouvez
            gérer vos choix à tout moment sur cette page en cliquant sur le
            bouton "Cookies" dans le pied de page. <br />
            Pour en savoir plus sur la gestion de vos données et vos droits,
            contactez-nous à l’adresse suivante :
            <a
              href='mailto:contact@webplayground.fr'
              style={{
                color: '#aab1bd',
                textDecoration: 'underline',
              }}>
              contact@webplayground
            </a>
          </DialogContentText>
          <img
            src={cookies}
            alt='cookies logo'
            style={{
              width: '10rem',
              height: '10rem',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.disallow()}>Refuser & Fermer</Button>
          <Button onClick={() => props.allow()}>Accepter & Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
