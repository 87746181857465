import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

// components
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Swiper from './components/swiper/SwiperCard';
import Search from './components/search/Search';
import CookiesConsent from './components/cookies/CookiesConsent';
import CircularProgress from '@mui/material/CircularProgress';
import Errors from './components/error/Error';
import Contact from './components/contact/Contact';
import Mobile from './components/isMobile/IsMobile';
import Popup from './components/popup/Popup';

// tools
import fetch from './service/fetch';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

// style
import './App.css';

export default function App() {
  const [cookies, setCookie] = useCookies(['session']);
  const [recipes, setRecipes] = useState<any>([]);
  const [search, setSearch] = useState('');
  // const [loader, setHomeLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalRecipe, setModalRecipe] = useState<any>([]);
  const [modalRecipeId, setModalRecipeId] = useState<number>(0);
  const [token, setToken] = useState<string>('');
  const [homeLoader, setHomeLoader] = useState(false);
  const [toggleForToolTips, setToggleForToolTips] = useState(false);

  const handleToggleForToolTips = () => {
    setToggleForToolTips(!toggleForToolTips);
  };

  useEffect(() => {
    if (recipes.length === 0) {
      setHomeLoader(true);
    } else {
      setHomeLoader(false);
    }
    const startSession = async () => {
      const data = await fetch.startSession();
      setCookie('session', data.session, { path: '/' });
      localStorage.setItem('token', data.session.token);
      setToken(data.session.token);

      // check if date exist in local storage
      if (localStorage.getItem('date')) {
        const date = new Date(localStorage.getItem('date')!);
        const today = new Date();
        const diffTime = Math.abs(today.getTime() - date.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 1) {
          localStorage.removeItem('date');
          localStorage.removeItem('token');
          localStorage.removeItem('requestCount');
          startSession();
        }
      } else {
        localStorage.setItem('date', new Date().toString());
        localStorage.setItem('requestCount', '0');
        startSession();
      }
    };
    startSession();
    cookiesManagement();
    setTimeout(() => {
      getLastRecipes();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLastRecipes = async () => {
    try {
      const response = await fetch.getLastRecipes();
      setRecipes(response);
      setHomeLoader(false);
      if (response.message) {
        setHomeLoader(true);
      } else {
        setHomeLoader(false);
      }
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getRecipeBySearch = async (search: string) => {
    try {
      if (search === '' || search === '#' || search.length === 0) {
        setSearch('');
        return getLastRecipes();
      }
      if (search.length === 1) {
        try {
          const response = await fetch.getAlphabeticalRecipes(search);
          setRecipes(response);
          setSearch(search);
          setHomeLoader(false);
          handleToggleForToolTips();
          return;
        } catch (error: any) {
          throw new Error(error);
        }
      }
      setSearch(search);
      const response = await fetch.getRecipeByIA(search, 'search', '');
      setRecipes(response);
      setHomeLoader(false);
      handleToggleForToolTips();
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getByFridge = async (fridge: any, params: any) => {
    try {
      setSearch(fridge);
      const response = await fetch.getRecipeByIA(fridge, 'fridge', params);
      setRecipes(response);
      setHomeLoader(false);
      handleToggleForToolTips();
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getRecipeById = () => {
    if (!recipes.message) {
      const result = recipes.find((recipe: any) => recipe.id === modalRecipeId);
      setModalRecipe(result);
    }
  };

  useEffect(() => {
    getRecipeById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRecipeId]);

  // isMobile
  const [openIsMobile, setOpenIsMobile] = useState(isMobile);

  // popup
  const [openPopup, setOpenPopup] = useState(false);

  // cookies
  const [openCookies, setOpenCookies] = useState(false);
  const cookiesManagement = () => {
    if (!localStorage.getItem('cookies')) {
      setOpenCookies(true);
    }
  };

  const allow = () => {
    localStorage.setItem('cookies', 'true');
    setOpenCookies(false);
  };

  const disallow = () => {
    localStorage.setItem('cookies', 'false');
    setOpenCookies(false);
  };

  // modal fridge
  const [openModalFrige, setOpenModalFrige] = useState(false);
  const handleOpenFridge = () => {
    setOpenModalFrige(true);
  };
  const handleCloseFridge = () => setOpenModalFrige(false);

  const fridge = {
    openModalFrige,
    handleOpenFridge,
    handleCloseFridge,
  };

  // modal contact
  const [openModalContact, setOpenModalContact] = useState(false);
  const handleOpenContact = () => {
    setOpenModalContact(true);
  };
  const handleCloseContact = () => setOpenModalContact(false);

  const contact = {
    openModalContact,
    handleOpenContact,
    handleCloseContact,
  };

  const props = {
    recipes,
    search,
    cookies,
    open,
    modalRecipe,
    modalRecipeId,
    openCookies,
    openIsMobile,
    openPopup,
    token,
    homeLoader,
    toggleForToolTips,
    setOpen,
    setHomeLoader,
    getRecipeBySearch,
    getRecipeById,
    setModalRecipe,
    getLastRecipes,
    setModalRecipeId,
    allow,
    disallow,
    setOpenCookies,
    setOpenIsMobile,
    getByFridge,
    setOpenPopup,
    fridge,
    contact,
    isAndroid,
    isIOS,
  };

  return (
    <div className='App'>
      {homeLoader && (
        <CircularProgress
          style={{
            position: 'absolute',
            left: '45%',
            transform: 'translateX(-50%)',
            top: '35%',
            zIndex: 100,
          }}
        />
      )}

      <Header />
      <CookiesConsent {...props} />
      <Popup {...props} />
      {isMobile && <Mobile {...props} />}
      {!recipes.message && <Swiper {...props} />}
      <Errors {...props} />
      <Contact {...props} />
      <Search {...props} />
      <Footer {...props} />
    </div>
  );
}
