import tools from '../../../service/tools';

export default function RecipeSteps({ ...props }: any) {
  return (
    <div>
      <h3
        style={{
          textAlign: 'center',
        }}>
        Préparation
      </h3>
      <ul
        style={{
          paddingLeft: '1rem',
          listStyle: 'square',
        }}>
        {props.modalRecipe.steps.map((step: any) => (
          <li
            key={tools.generateKey()}
            style={{
              margin: '0 1rem',
            }}>
            <p>
              {/* if the first caractére is - remove it */}
              {step.charAt(0) === '-'
                ? step.slice(1)
                : step.charAt(0) === ' '
                ? step.slice(1)
                : step}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}
