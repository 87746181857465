import { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import emailjs from '@emailjs/browser';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 0,
  '@media (max-width: 500px)': {
    width: '95%',
  },
};

function Contact({ ...props }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [colorButton, setColorButton] = useState<any>('primary');
  const [disableButton, setDisableButton] = useState(false);
  const form = useRef<any>();

  useEffect(() => {
    if (firstName && lastName && email && message) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [firstName, lastName, email, message]);

  const handleSubmit = (event: any) => {
    if (!disableButton) {
      event.preventDefault();
      setDisableButton(true);
      emailjs
        .sendForm(
          'service_svov39i',
          'template_8pw3e41',
          form.current,
          'JGHAzkiJKLJSSUELJ',
        )
        .then(
          () => {
            setFirstName('');
            setLastName('');
            setEmail('');
            setMessage('');
            setColorButton('success');
            setTimeout(() => {
              props.contact.handleCloseContact();
              setColorButton('primary');
            }, 2000);
          },
          () => {
            setColorButton('error');
          },
        );
    }
  };

  const handleReset = (event: any) => {
    event.preventDefault();
    setFirstName('');
    setLastName('');
    setEmail('');
    setMessage('');
  };

  return (
    <Modal
      open={props.contact.openModalContact}
      onClose={props.contact.handleCloseContact}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={style}>
        <Typography
          id='modal-modal-title'
          variant='h6'
          component='h2'
          sx={{
            textAlign: 'center',
            padding: '1rem',
          }}>
          Un problème ? Une question ? Contactez-nous !
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}>
          <Grid
            xs={12}
            sm={7}
            md={8}
            xl={5}
            sx={{
              height: '70%',
              textAlign: 'center',
            }}>
            <Box
              className='contact-form'
              component='form'
              // onSubmit={handleSubmit}
              ref={form}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                '& > :not(style)': { m: 1, width: '35ch' },
              }}
              noValidate
              autoComplete='off'>
              <TextField
                id='outlined-basic'
                label='Nom'
                variant='outlined'
                required
                sx={{ width: '100%' }}
                type='text'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                name='lastName'
              />
              <TextField
                id='outlined-basic'
                label='Prénom'
                variant='outlined'
                type={'text'}
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                name='firstName'
              />
              <TextField
                id='outlined-basic'
                label='Email'
                type={'email'}
                variant='outlined'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name='email'
              />
              <TextField
                id='outlined-multiline-static'
                label='Message'
                multiline
                rows={4}
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name='message'
              />
              <Stack
                direction='row'
                spacing={2}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '1rem',
                }}>
                <Button
                  disabled={disableButton}
                  variant='outlined'
                  color={colorButton}
                  onClick={(e) => handleSubmit(e)}>
                  {colorButton === 'success' && (
                    <span role='img' aria-label='emoji'>
                      👍
                    </span>
                  )}
                  {colorButton === 'error' && (
                    <span role='img' aria-label='emoji'>
                      👎
                    </span>
                  )}
                  {colorButton === 'primary' && 'Envoyer'}
                </Button>
                <Button variant='outlined' onClick={(e) => handleReset(e)}>
                  Effacer
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => props.contact.handleCloseContact()}>
                  Fermer
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default Contact;
