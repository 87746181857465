import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function CardsHeader({ ...props }) {
  return (
    <CardContent
      sx={{
        paddingBottom: '0',
      }}>
      <Typography
        variant='h2'
        color='text.secondary'
        style={{
          color: 'white',
          fontSize: '1.3rem',
          fontWeight: 600,
          textAlign: 'left',
        }}>
        {props.recipe.name.charAt(0).toUpperCase() +
          props.recipe.name.slice(1, 30) +
          '...'}
      </Typography>
    </CardContent>
  );
}
