import seafood_1 from '../../../assets/icone/seafood/seafood 1.png';
import seafood_2 from '../../../assets/icone/seafood/seafood 2.png';
import seafood_3 from '../../../assets/icone/seafood/seafood 3.png';
import seafood_4 from '../../../assets/icone/seafood/seafood 4.png';
import seafood_5 from '../../../assets/icone/seafood/seafood 5.png';
import seafood_6 from '../../../assets/icone/seafood/seafood 6.png';
import seafood_7 from '../../../assets/icone/seafood/seafood 7.png';
import seafood_8 from '../../../assets/icone/seafood/seafood 8.png';
import seafood_9 from '../../../assets/icone/seafood/seafood 9.png';
import seafood_10 from '../../../assets/icone/seafood/seafood 10.png';
import seafood_11 from '../../../assets/icone/seafood/seafood 11.png';
import seafood_12 from '../../../assets/icone/seafood/seafood 12.png';
import seafood_13 from '../../../assets/icone/seafood/seafood 13.png';
import seafood_14 from '../../../assets/icone/seafood/seafood 14.png';
import seafood_15 from '../../../assets/icone/seafood/seafood 15.png';
import seafood_16 from '../../../assets/icone/seafood/seafood 16.png';
import seafood_17 from '../../../assets/icone/seafood/seafood 17.png';
import seafood_18 from '../../../assets/icone/seafood/seafood 18.png';
import seafood_19 from '../../../assets/icone/seafood/seafood 19.png';
import seafood_20 from '../../../assets/icone/seafood/seafood 20.png';
import seafood_21 from '../../../assets/icone/seafood/seafood 21.png';
import seafood_22 from '../../../assets/icone/seafood/seafood 22.png';
import seafood_23 from '../../../assets/icone/seafood/seafood 23.png';
import seafood_24 from '../../../assets/icone/seafood/seafood 24.png';
import seafood_25 from '../../../assets/icone/seafood/seafood 25.png';

const SeafoodsList: Array<{ name: string; avatar_url: string; id: string }> = [
  {
    name: '',
    avatar_url: '',
    id: 'seafood_0',
  },
  {
    name: 'Carpe',
    avatar_url: seafood_1,
    id: 'seafood_1',
  },
  {
    name: 'Poisson-chat',
    avatar_url: seafood_2,
    id: 'seafood_2',
  },
  {
    name: 'Crabe',
    avatar_url: seafood_3,
    id: 'seafood_3',
  },
  {
    name: 'Anguille',
    avatar_url: seafood_4,
    id: 'seafood_4',
  },
  {
    name: 'Homard',
    avatar_url: seafood_5,
    id: 'seafood_5',
  },
  {
    name: 'Maquereau',
    avatar_url: seafood_6,
    id: 'seafood_6',
  },
  {
    name: 'Moule',
    avatar_url: seafood_7,
    id: 'seafood_7',
  },
  {
    name: 'Huître',
    avatar_url: seafood_8,
    id: 'seafood_8',
  },
  {
    name: 'Crevette-rose',
    avatar_url: seafood_9,
    id: 'seafood_9',
  },
  {
    name: 'Saumon',
    avatar_url: seafood_10,
    id: 'seafood_10',
  },
  {
    name: 'Sardine',
    avatar_url: seafood_11,
    id: 'seafood_11',
  },
  {
    name: 'Coquille',
    avatar_url: seafood_12,
    id: 'seafood_12',
  },
  {
    name: 'Crevette-grise',
    avatar_url: seafood_13,
    id: 'seafood_13',
  },
  {
    name: 'Calamar',
    avatar_url: seafood_14,
    id: 'seafood_14',
  },
  {
    name: 'Truite',
    avatar_url: seafood_15,
    id: 'seafood_15',
  },
  {
    name: 'Thon',
    avatar_url: seafood_16,
    id: 'seafood_16',
  },
  {
    name: 'Tourteaux',
    avatar_url: seafood_17,
    id: 'seafood_17',
  },
  {
    name: 'Langouste',
    avatar_url: seafood_18,
    id: 'seafood_18',
  },
  {
    name: 'Bulot',
    avatar_url: seafood_19,
    id: 'seafood_19',
  },
  {
    name: 'Seiches',
    avatar_url: seafood_20,
    id: 'seafood_20',
  },
  {
    name: 'Poulpe',
    avatar_url: seafood_21,
    id: 'seafood_21',
  },
  {
    name: 'Langoustine',
    avatar_url: seafood_22,
    id: 'seafood_22',
  },
  {
    name: 'Coque',
    avatar_url: seafood_23,
    id: 'seafood_23',
  },
  {
    name: 'poisson-pané',
    avatar_url: seafood_24,
    id: 'seafood_24',
  },
  {
    name: 'Raie',
    avatar_url: seafood_25,
    id: 'seafood_25',
  },
];
export default SeafoodsList;
