const tools = {
  defaultPeople: 0,
  ingredients: [] as string[],
  people: 0,

  adjustRecipe: (
    ingredients: string[],
    people: number,
    defaultPeople: number,
  ) => {
    const delta = people / defaultPeople;
    const newIngredients = [] as string[];
    const newQuantity = ingredients.map((ingredient) => {
      const quantity = parseInt(ingredient.split(' ')[0]);
      if (quantity) {
        return `${Math.round(quantity * delta)} ${ingredient
          .split(' ')
          .slice(1)
          .join(' ')}`;
      } else {
        return ingredient;
      }
    });
    newIngredients.push(...newQuantity);
    return newIngredients;
  },
};

export default tools;
