import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';

// tools
import tools from '../../service/tools';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} followCursor />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function PositionedTooltips({ ...props }) {
  const [missingIngredients, setMissingIngredients] = React.useState([]);
  const [cardWidth] = React.useState(
    document.querySelector('.cards')?.clientWidth,
  );
  const [toggle, setToggle] = React.useState(false);

  const checkWhoseMissing = () => {
    const ingredients = props.recipe.ingredients;
    const search: any = [];
    props.search.split(' ').forEach((item: any) => {
      search.push(item.toLowerCase());
    });
    // fix œ on oe
    const keywordsArray = search.map((keyword: any) => {
      return keyword.replace(/œ/g, 'oe');
    });

    // add patates if pommes de terre in keywordsArray
    if (keywordsArray.includes('pommes de terre')) {
      keywordsArray.push('patates');
    }
    // remove duplicate search
    const searchArray = keywordsArray.filter(
      (item: any, index: any) => keywordsArray.indexOf(item) === index,
    );
    // remove index if length = 0
    const searchArrayClear = searchArray.filter(
      (item: any) => item.length !== 0,
    );
    // remove trademark with ascii code
    const searchArrayTrademark = searchArrayClear.map((item: any) => {
      return item.replace(/®/g, '');
    });

    // remove , from searchArrayTrademark
    const searchArrayTrademarkClear = searchArrayTrademark.map((item: any) => {
      return item.replace(/,/g, '');
    });

    const check: any = [];
    // check if ingredients contains all search if not push ingredient to missing
    for (const ingredient of ingredients) {
      for (const item of searchArrayTrademarkClear) {
        const regex = new RegExp('\\b' + item + '\\b', 'i');
        if (regex.test(ingredient)) {
          check.push(ingredient);
          break;
        }
      }
    }
    const missingIngredient = ingredients.filter(
      (item: any) => !check.includes(item),
    );
    setMissingIngredients(missingIngredient);
  };

  React.useEffect(() => {
    checkWhoseMissing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  React.useEffect(() => {
    setToggle(!toggle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.toggleForToolTips]);

  return (
    <Box sx={{ width: cardWidth, maxWidth: '22rem', position: 'absolute' }}>
      <Grid container justifyContent='center'>
        <Grid item>
          <HtmlTooltip
            TransitionComponent={Zoom}
            title={
              { missingIngredients }.missingIngredients.length > 0 ? (
                { missingIngredients } && (
                  <React.Fragment>
                    <ul style={{ paddingLeft: 10 }}>
                      {missingIngredients.map((item: any, index: any) => (
                        <li key={tools.generateKey()}>
                          <p key={index}>{item}</p>
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                )
              ) : (
                <React.Fragment>
                  <p>Tous les ingrédients sont dans votre frigo</p>
                </React.Fragment>
              )
            }
            placement='top-end'>
            <Button
              sx={{
                color: 'white',
                borderRadius: '20px 20px 0 0',
                background:
                  'linear-gradient(0deg, rgba(255,149,188,0.5) 0%, rgba(255,149,188,0.9) 100%)',
                overflow: 'hidden',
                animation: 'pulse 1s infinite',
                '@keyframes pulse': {
                  '0%': {
                    boxShadow: '0 0 0 0 rgba(123, 237, 255, 0.7)',
                  },
                  '70%': {
                    boxShadow: '0 0 0 10px rgba(123, 237, 255, 0)',
                  },
                  '100%': {
                    boxShadow: '0 0 0 0 rgba(123, 237, 255, 0)',
                  },
                },
              }}>
              <span>
                {missingIngredients.length === 0
                  ? 'Il ne vous manque aucun ingrédient pour cette recette'
                  : `Il vous manque ${missingIngredients.length} 
                    ${
                      missingIngredients.length !== 1
                        ? 'ingrédients'
                        : 'ingrédient'
                    } pour cette recette`}
              </span>
            </Button>
          </HtmlTooltip>
        </Grid>
      </Grid>
    </Box>
  );
}
