import { useState, useEffect, forwardRef, ReactElement, Ref } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function AlertDialogSlide({ ...props }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.openPopup);
  }, [props.openPopup]);

  const close = () => {
    setOpen(false);
    props.setOpenPopup(false);
  };

  return (
    <div>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: '#f5f5f5',
            maxWidth: '760px',
          },
        }}
        onClose={() => {
          props.disallow();
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby='alert-dialog-slide-description'>
        <DialogTitle>{'Attention'}</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '@media (max-width: 550px)': {
              flexDirection: 'column',
            },
          }}>
          <DialogContentText
            sx={{
              fontSize: '1.2rem',
            }}
            id='alert-dialog-slide-description'>
            Merci de ne pas utiliser de caractères spéciaux dans votre recherche
            (ex: !, ?, _, - etc.)
            <br />
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
