import { useEffect, useState, useRef } from 'react';

// tools
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ReactToPrint from 'react-to-print';
import fetch from '../../service/fetch';

// components
import RecipeHeader from './_recipe/RecipeHeader';
import RecipeImage from './_recipe/RecipeImage';
import RecipeIngredients from './_recipe/RecipeIngredients';
import RecipeSteps from './_recipe/RecipeSteps';
import RecipeFooter from './_recipe/RecipeFooter';
import BoxComments from './_recipe/BoxComment';

//  styles
import './Print.scss';

export default function FullBorderedGrid({ ...props }) {
  const [color, setColor] = useState('#4bc2e4');
  const [toggle, setToggle] = useState(false);
  const [comment, setComment] = useState(false);
  const [updateComment, setUpdateComment] = useState(false);
  const [listOfComments, setListOfComments] = useState([]);
  const componentRef: any = useRef();
  const [modalHeigth, setModalHeigth] = useState(
    document.querySelector('.print_recipe_all')?.clientHeight,
  );

  const toggleRecipe = () => {
    setToggle(!toggle);
  };

  const toggleUpdateComment = () => {
    setUpdateComment(!updateComment);
  };

  const openComment = () => {
    setComment((prev) => !prev);
  };

  useEffect(() => {
    if (!props.open) {
      setComment(false);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.modalRecipe) {
      fetch.getComments(props.modalRecipe.id).then((res) => {
        setListOfComments(res);
      });
    }
  }, [props.modalRecipe, updateComment]);

  useEffect(() => {
    setModalHeigth(document.querySelector('.print_recipe_all')?.clientHeight);
  }, []);

  return (
    <Box
      className='print_recipe_all'
      sx={{ flexGrow: 1, p: 0, minHeight: '95vh' }}
      ref={(el: any) => (componentRef.current = el)}>
      <Box
        sx={{
          height: modalHeigth,
          position: 'absolute',
          right: '1rem',
        }}>
        <FontAwesomeIcon
          style={{
            margin: '1rem',
            fontSize: '3rem',
            color: color,
            position: 'sticky',
            top: '0',
            right: '0.5rem',
            cursor: 'pointer',
            transition: 'color 0.3s ease',
            zIndex: 100,
          }}
          className='card_cross print_cursor'
          icon={solid('xmark')}
          onClick={() => props.handleClose()}
          onMouseEnter={() => {
            setColor('#619DDA');
          }}
          onMouseLeave={() => {
            setColor('#4bc2e4');
          }}
        />
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          '--Grid-borderWidth': '1px',
          borderTop: 'var(--Grid-borderWidth) solid',
          borderLeft: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider',
          '& > div': {
            borderRight: 'var(--Grid-borderWidth) solid',
            borderBottom: 'var(--Grid-borderWidth) solid',
            borderColor: 'divider',
          },
          minHeight: '95vh',
        }}>
        <Grid {...{ xs: 12, sm: 6, md: 6, lg: 4 }} sx={{ minHeight: '50%' }}>
          <ReactToPrint
            trigger={() => (
              <FontAwesomeIcon
                className='print_cursor'
                icon={solid('print')}
                style={{
                  margin: '1rem',
                  fontSize: '2rem',
                  color: color,
                  cursor: 'pointer',
                  transition: 'color 0.3s ease',
                }}
                onMouseEnter={() => {
                  setColor('#619DDA');
                }}
                onMouseLeave={() => {
                  setColor('#4bc2e4');
                }}
              />
            )}
            content={() => componentRef.current}
            bodyClass='print'
          />
          <RecipeHeader
            {...props}
            listOfComments={listOfComments}
            comment={comment}
            toggle={toggleRecipe}
            openComment={openComment}
            updateComment={updateComment}
          />
        </Grid>
        <Grid
          {...{ xs: 12, sm: 6, md: 6, lg: 8 }}
          sx={{ minHeight: '50%', display: 'flex' }}>
          <BoxComments
            {...props}
            listOfComments={listOfComments}
            comment={comment}
            toggleUpdateComment={toggleUpdateComment}
            updateComment={updateComment}
          />
          <RecipeImage {...props} />
        </Grid>
        <Grid {...{ xs: 12, sm: 6, md: 6, lg: 6 }} sx={{ minHeight: '50%' }}>
          <RecipeIngredients {...props} toggle={toggleRecipe} />
        </Grid>
        <Grid {...{ xs: 12, sm: 6, md: 6, lg: 6 }} sx={{ minHeight: '50%' }}>
          <RecipeSteps {...props} />
        </Grid>
        <Grid
          {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
          sx={{
            alignSelf: 'flex-end',
            minHeight: '5%',
            '--Grid-borderWidth': '0px',
          }}>
          <RecipeFooter {...props} />
        </Grid>
      </Grid>
    </Box>
  );
}
