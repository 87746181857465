import params_1 from '../../../assets/icone/params/params_aperitif_1.png';
import params_2 from '../../../assets/icone/params/params_cake_1.png';
import params_3 from '../../../assets/icone/params/params_vegan_1.png';
import params_4 from '../../../assets/icone/params/params_vegetarian_1.png';
import params_5 from '../../../assets/icone/params/params_gluten_1.png';
import params_6 from '../../../assets/icone/params/params_bio_1.png';

const paramsList: Array<{ name: string; avatar_url: string; id: string }> = [
  {
    name: '',
    avatar_url: '',
    id: '',
  },
  {
    name: 'Désactiver-les-recettes-apéritifs',
    avatar_url: params_1,
    id: 'params_1',
  },
  {
    name: 'Désactiver-les-recettes-gateaux',
    avatar_url: params_2,
    id: 'params_2',
  },
  {
    name: 'Recettes-vegan',
    avatar_url: params_3,
    id: 'params_3',
  },
  {
    name: 'Recettes-vegetariennes',
    avatar_url: params_4,
    id: 'params_4',
  },
  {
    name: 'Recettes-sans-gluten',
    avatar_url: params_5,
    id: 'params_5',
  },
  {
    name: 'Recettes-bio',
    avatar_url: params_6,
    id: 'params_6',
  },
];

export default paramsList;
