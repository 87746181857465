import dairy_1 from '../../../assets/icone/dairy/dairy 1.png';
import dairy_2 from '../../../assets/icone/dairy/dairy 2.png';
import dairy_3 from '../../../assets/icone/dairy/dairy 3.png';
import dairy_4 from '../../../assets/icone/dairy/dairy 4.png';
import dairy_5 from '../../../assets/icone/dairy/dairy 5.png';
import dairy_6 from '../../../assets/icone/dairy/dairy 6.png';
import dairy_7 from '../../../assets/icone/dairy/dairy 7.png';
import dairy_8 from '../../../assets/icone/dairy/dairy 8.png';
import dairy_9 from '../../../assets/icone/dairy/dairy 9.png';
import dairy_10 from '../../../assets/icone/dairy/dairy 10.png';
import dairy_11 from '../../../assets/icone/dairy/dairy 11.png';
import dairy_12 from '../../../assets/icone/dairy/dairy 12.png';
import dairy_13 from '../../../assets/icone/dairy/dairy 13.png';
import dairy_14 from '../../../assets/icone/dairy/dairy 14.png';

const DairyList: Array<{ name: string; avatar_url: string; id: string }> = [
  {
    name: '',
    avatar_url: '',
    id: 'dairy_0',
  },
  {
    name: 'Beurre',
    avatar_url: dairy_1,
    id: 'dairy_1',
  },
  {
    name: 'Babeurre',
    avatar_url: dairy_2,
    id: 'dairy_2',
  },
  {
    name: 'Lait-de-coco',
    avatar_url: dairy_3,
    id: 'dairy_3',
  },
  {
    name: 'Crème-fraîche',
    avatar_url: dairy_4,
    id: 'dairy_4',
  },
  {
    name: 'Oeuf',
    avatar_url: dairy_5,
    id: 'dairy_5',
  },
  {
    name: 'Crème-glacée',
    avatar_url: dairy_6,
    id: 'dairy_6',
  },
  {
    name: 'Lait',
    avatar_url: dairy_7,
    id: 'dairy_7',
  },
  {
    name: 'Crème-aigre',
    avatar_url: dairy_8,
    id: 'dairy_8',
  },
  {
    name: 'Yaourt',
    avatar_url: dairy_9,
    id: 'dairy_9',
  },
  {
    name: 'Crème-liquide',
    avatar_url: dairy_10,
    id: 'dairy_10',
  },
  {
    name: 'Chantilly',
    avatar_url: dairy_11,
    id: 'dairy_11',
  },
  {
    name: 'Lait-fermenté',
    avatar_url: dairy_12,
    id: 'dairy_12',
  },
  {
    name: 'Lait-de-chèvre',
    avatar_url: dairy_13,
    id: 'dairy_13',
  },
  {
    name: 'Lait-de-soja',
    avatar_url: dairy_14,
    id: 'dairy_14',
  },
];
export default DairyList;
