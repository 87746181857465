import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export default function error404() {
  return (
    <Box
      component='main'
      sx={{
        height: '100vh',
      }}>
      <Box
        className='container'
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Paper elevation={9} sx={{ p: 10, m: 10 }}>
          <h1>404</h1>
          <h2>Pas de contenu</h2>
          <a href='/'>Revenir à la page d'accueil</a>
        </Paper>
      </Box>
    </Box>
  );
}
