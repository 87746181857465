import nuts_1 from '../../../assets/icone/nuts/nuts 1.png';
import nuts_2 from '../../../assets/icone/nuts/nuts 2.png';
import nuts_3 from '../../../assets/icone/nuts/nuts 3.png';
import nuts_4 from '../../../assets/icone/nuts/nuts 4.png';
import nuts_5 from '../../../assets/icone/nuts/nuts 5.png';
import nuts_6 from '../../../assets/icone/nuts/nuts 6.png';
import nuts_7 from '../../../assets/icone/nuts/nuts 7.png';
import nuts_8 from '../../../assets/icone/nuts/nuts 8.png';
import nuts_9 from '../../../assets/icone/nuts/nuts 9.png';
import fruit_21 from '../../../assets/icone/fruit/fruit 21.png';
import fruit_32 from '../../../assets/icone/fruit/fruit 32.png';

const NutsList: Array<{ name: string; avatar_url: string; id: string }> = [
  {
    name: '',
    avatar_url: '',
    id: 'nuts_0',
  },
  {
    name: 'Amande',
    avatar_url: nuts_1,
    id: 'nuts_1',
  },
  {
    name: 'Noix-de-cajou',
    avatar_url: nuts_2,
    id: 'nuts_2',
  },
  {
    name: 'Macadamia',
    avatar_url: nuts_3,
    id: 'nuts_3',
  },
  {
    name: 'Cacahuète',
    avatar_url: nuts_4,
    id: 'nuts_4',
  },
  {
    name: "Beurre-d'arachide",
    avatar_url: nuts_5,
    id: 'nuts_5',
  },
  {
    name: 'Noix-de-pécan',
    avatar_url: nuts_6,
    id: 'nuts_6',
  },
  {
    name: 'Noix',
    avatar_url: nuts_7,
    id: 'nuts_7',
  },
  {
    name: 'Noisette',
    avatar_url: nuts_8,
    id: 'nuts_8',
  },
  {
    name: 'Pistache',
    avatar_url: nuts_9,
    id: 'nuts_9',
  },
  {
    name: 'Marron',
    avatar_url: fruit_32,
    id: 'nuts_10',
  },
  {
    name: 'Châtaigne',
    avatar_url: fruit_21,
    id: 'nuts_11',
  },
];
export default NutsList;
