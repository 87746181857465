import spices_1 from '../../../assets/icone/spices/spices 1.png';
import spices_2 from '../../../assets/icone/spices/spices 2.png';
import spices_3 from '../../../assets/icone/spices/spices 3.png';
import spices_4 from '../../../assets/icone/spices/spices 4.png';
import spices_5 from '../../../assets/icone/spices/spices 5.png';
import spices_6 from '../../../assets/icone/spices/spices 6.png';
import spices_7 from '../../../assets/icone/spices/spices 7.png';
import spices_8 from '../../../assets/icone/spices/spices 8.png';
import spices_9 from '../../../assets/icone/spices/spices 9.png';
import spices_10 from '../../../assets/icone/spices/spices 10.png';
import spices_11 from '../../../assets/icone/spices/spices 11.png';
import spices_12 from '../../../assets/icone/spices/spices 12.png';
import spices_13 from '../../../assets/icone/spices/spices 13.png';
import spices_14 from '../../../assets/icone/spices/spices 14.png';
import spices_15 from '../../../assets/icone/spices/spices 15.png';
import spices_16 from '../../../assets/icone/spices/spices 16.png';
import spices_17 from '../../../assets/icone/spices/spices 17.png';
import spices_18 from '../../../assets/icone/spices/spices 18.png';
import spices_19 from '../../../assets/icone/spices/spices 19.png';
import spices_20 from '../../../assets/icone/spices/spices 20.png';
import spices_21 from '../../../assets/icone/spices/spices 21.png';
import spices_22 from '../../../assets/icone/spices/spices 22.png';
import spices_23 from '../../../assets/icone/spices/spices 23.png';
import spices_24 from '../../../assets/icone/spices/spices 24.png';
import spices_25 from '../../../assets/icone/spices/spices 25.png';
import spices_26 from '../../../assets/icone/spices/spices 26.png';
import spices_27 from '../../../assets/icone/spices/spices 27.png';
import spices_28 from '../../../assets/icone/spices/spices 28.png';
import spices_29 from '../../../assets/icone/spices/spices 29.png';
import spices_30 from '../../../assets/icone/spices/spices 30.png';
import spices_31 from '../../../assets/icone/spices/spices 31.png';
import spices_32 from '../../../assets/icone/spices/spices 32.png';
import spices_33 from '../../../assets/icone/spices/spices 33.png';

const spicesList: Array<{ name: string; avatar_url: string; id: string }> = [
  {
    name: '',
    avatar_url: '',
    id: 'spices_0',
  },
  {
    name: 'Basilic',
    avatar_url: spices_1,
    id: 'spices_1',
  },
  {
    name: 'Coriandre',
    avatar_url: spices_2,
    id: 'spices_2',
  },
  {
    name: 'Ail',
    avatar_url: spices_3,
    id: 'spices_3',
  },
  {
    name: 'Gingembre',
    avatar_url: spices_4,
    id: 'spices_4',
  },
  {
    name: 'Thym',
    avatar_url: spices_5,
    id: 'spices_5',
  },
  {
    name: 'Menthe',
    avatar_url: spices_6,
    id: 'spices_6',
  },
  {
    name: 'Origan',
    avatar_url: spices_7,
    id: 'spices_7',
  },
  {
    name: 'Graines',
    avatar_url: spices_8,
    id: 'spices_8',
  },
  {
    name: 'Paprika',
    avatar_url: spices_9,
    id: 'spices_9',
  },
  {
    name: 'Quatre-épices',
    avatar_url: spices_10,
    id: 'spices_10',
  },
  {
    name: 'Noix-de-muscade',
    avatar_url: spices_11,
    id: 'spices_11',
  },
  {
    name: 'Bouillon-de-boeuf',
    avatar_url: spices_12,
    id: 'spices_12',
  },
  {
    name: 'Bouillon-au-poulet',
    avatar_url: spices_13,
    id: 'spices_13',
  },
  {
    name: "Bouillon-à-l'Agneau",
    avatar_url: spices_14,
    id: 'spices_14',
  },
  {
    name: 'Vanille',
    avatar_url: spices_15,
    id: 'spices_15',
  },
  {
    name: 'Badiane',
    avatar_url: spices_16,
    id: 'spices_16',
  },
  {
    name: 'Cannelle',
    avatar_url: spices_17,
    id: 'spices_17',
  },
  {
    name: 'Poivre',
    avatar_url: spices_18,
    id: 'spices_18',
  },
  {
    name: 'Sel',
    avatar_url: spices_19,
    id: 'spices_19',
  },
  {
    name: 'Curcuma',
    avatar_url: spices_20,
    id: 'spices_20',
  },
  {
    name: 'Piment',
    avatar_url: spices_21,
    id: 'spices_21',
  },
  {
    name: 'Safran',
    avatar_url: spices_22,
    id: 'spices_22',
  },
  {
    name: 'Aneth',
    avatar_url: spices_23,
    id: 'spices_23',
  },
  {
    name: 'Ciboulette',
    avatar_url: spices_24,
    id: 'spices_24',
  },
  {
    name: 'Cumin',
    avatar_url: spices_25,
    id: 'spices_25',
  },
  {
    name: 'Curry',
    avatar_url: spices_26,
    id: 'spices_26',
  },
  {
    name: 'Estragon',
    avatar_url: spices_27,
    id: 'spices_27',
  },
  {
    name: 'Laurier',
    avatar_url: spices_28,
    id: 'spices_28',
  },
  {
    name: 'Persil',
    avatar_url: spices_29,
    id: 'spices_29',
  },
  {
    name: 'Romarin',
    avatar_url: spices_30,
    id: 'spices_30',
  },
  {
    name: 'Sucre',
    avatar_url: spices_31,
    id: 'spices_31',
  },
  {
    name: 'Sucre-glace',
    avatar_url: spices_32,
    id: 'spices_32',
  },
  {
    name: 'Sucre-vanillé',
    avatar_url: spices_33,
    id: 'spices_33',
  },
];

export default spicesList;
