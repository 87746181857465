import liquids_1 from '../../../assets/icone/liquids/liquids 1.png';
import liquids_2 from '../../../assets/icone/liquids/liquids 2.png';
import liquids_3 from '../../../assets/icone/liquids/liquids 3.png';
import liquids_4 from '../../../assets/icone/liquids/liquids 4.png';
import liquids_5 from '../../../assets/icone/liquids/liquids 5.png';
import liquids_6 from '../../../assets/icone/liquids/liquids 6.png';
import liquids_7 from '../../../assets/icone/liquids/liquids 7.png';
import liquids_8 from '../../../assets/icone/liquids/liquids 8.png';
import liquids_9 from '../../../assets/icone/liquids/liquids 9.png';
import liquids_10 from '../../../assets/icone/liquids/liquids 10.png';
import liquids_11 from '../../../assets/icone/liquids/liquids 11.png';
import liquids_12 from '../../../assets/icone/liquids/liquids 12.png';
import liquids_13 from '../../../assets/icone/liquids/liquids 13.png';

const LiquidsList: Array<{ name: string; avatar_url: string; id: string }> = [
  {
    name: '',
    avatar_url: '',
    id: 'liquids_0',
  },
  {
    name: 'Jus-de-pomme',
    avatar_url: liquids_1,
    id: 'liquids_1',
  },
  {
    name: 'Bière',
    avatar_url: liquids_2,
    id: 'liquids_2',
  },
  {
    name: "Huile-d'olive",
    avatar_url: liquids_3,
    id: 'liquids_3',
  },
  {
    name: "jus-d'orange",
    avatar_url: liquids_4,
    id: 'liquids_4',
  },
  {
    name: 'Vin-rouge',
    avatar_url: liquids_5,
    id: 'liquids_5',
  },
  {
    name: 'Huile-végétale',
    avatar_url: liquids_6,
    id: 'liquids_6',
  },
  {
    name: 'Vin-blanc',
    avatar_url: liquids_7,
    id: 'liquids_7',
  },
  {
    name: 'Champagne',
    avatar_url: liquids_8,
    id: 'liquids_8',
  },
  {
    name: 'Muscat',
    avatar_url: liquids_9,
    id: 'liquids_9',
  },
  {
    name: 'Rhum',
    avatar_url: liquids_10,
    id: 'liquids_10',
  },
  {
    name: 'Whisky',
    avatar_url: liquids_11,
    id: 'liquids_11',
  },
  {
    name: 'Calvados',
    avatar_url: liquids_12,
    id: 'liquids_12',
  },
  {
    name: 'Eau',
    avatar_url: liquids_13,
    id: 'liquids_13',
  },
];
export default LiquidsList;
