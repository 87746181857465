import { useState, useEffect } from 'react';

// tools
import tools from '../../../service/adjustRecipe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import generator from '../../../service/tools';
import Box from '@mui/material/Box';

// components
import RecipePeople from './RecipePeople';

export default function RecipeIngredients({ ...props }: any) {
  const [ingredients, setIngredients] = useState<any>([]);

  useEffect(() => {
    if (props.open) {
      setIngredients(
        tools.adjustRecipe(
          tools.ingredients,
          tools.people,
          tools.defaultPeople,
        ),
      );
    } else {
      setIngredients([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalRecipe, props.open, props.toggle, tools.people]);

  return (
    <div>
      <h3
        style={{
          textAlign: 'center',
        }}>
        Ingrédients
      </h3>

      <Box
        sx={{
          '@media (min-width: 600px)': {
            display: 'none',
          },
        }}>
        <RecipePeople {...props} />
      </Box>

      <ul
        style={{
          listStyle: 'none',
          paddingLeft: '0',
        }}>
        {ingredients.map((ingredient: any) => (
          <li
            key={generator.generateKey()}
            style={{
              margin: '0 1rem',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '2rem',
            }}>
            <FontAwesomeIcon
              icon={solid('check')}
              style={{ marginRight: '1rem' }}
            />
            <p>
              {/* if the first caractére is - remove it */}
              {ingredient.charAt(0) === '-'
                ? ingredient.slice(1)
                : ingredient.charAt(0) === ' '
                ? ingredient.slice(1)
                : ingredient}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}
